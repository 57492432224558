import {Button, Dropdown, Table, Tag} from "antd";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faBars,
  faCheckDouble,
  faExclamationTriangle, faInfoCircle,
  faPencil,
  faTriangleExclamation
} from "@fortawesome/free-solid-svg-icons";
import {exportationContainerStateEnum} from "../../../shared/enums/exportation-container-state.enum";
import DetalleExportationTable from "../DetalleExportationTable";
import {
  updateExportationContainer,
  updateExportationContainerLoaded
} from "../../../Servicios/ExportationContainerService";
import {errorMessage, successMessage, warningMessage} from "../../../utils/toast-message";
import {useState} from "react";
import DetailExportationDrawer from "../../../Componentes/DetailExportationDrawer";
import DetalleExportInfo from "./DetalleExportInfo";
import {validarPermisos} from "../../../Servicios/AuthService";
import {permisos} from "../../../utils/permisos";
import ShowWhen from "../../../Componentes/ShowWhen/ShowWhen";

const ExportationContainerTable = ({ filters,
                                     currentUser,
                                     totalElements,
                                     exportationContainers,
                                     onUpdate,
                                     onPageChange,
                                     onUpdateExportationContainer }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataExportationDrawer, setDataExportationDrawer] = useState({});

  const items = element => {
    const items = [
      {
        key: "1",
        icon: <FontAwesomeIcon icon={faPencil} />,
        label: "Modificar",
        onClick: () => onUpdateExportationContainer(element),
        isVisible: validarPermisos(permisos.PLANIFICACION_CONTENEDOR_AGREGAR)
      },
      {
        key: "2",
        icon: <FontAwesomeIcon icon={faCheckDouble} />,
        label: "Marcar como cargado",
        onClick: () => handleMarcarTerminado(element),
        isVisible: element.loadedAt === null
      },
      {
        key: "3",
        icon: <FontAwesomeIcon icon={faExclamationTriangle} />,
        label: "Marcar como Coneado",
        onClick: () => handleConedExportationContainer(element),
        isVisible: !element.conePlaced && validarPermisos(permisos.PLANIFICACION_CONTENEDOR_CONEAR)
      },
      {
        key: "4",
        icon: <FontAwesomeIcon icon={faTriangleExclamation} />,
        label: "Marcar como NO coneado",
        onClick: () => handleConedExportationContainer(element),
        isVisible: element.conePlaced && validarPermisos(permisos.PLANIFICACION_CONTENEDOR_CONEAR)
      },
      {
        key: "5",
        icon: <FontAwesomeIcon icon={faInfoCircle} />,
        label: "Ver Detalle",
        onClick: () => {
          setOpen(true);
          setDataExportationDrawer(element);
        },
        isVisible: true
      }
    ];

    return items.filter(e => e.isVisible);
  };

  const columns = [
    {
      title: "",
      dataIndex: "options",
      key: "options",
      fixed: 'left',
      width: '50px',
      render: (_, element) => (
        <Dropdown trigger={['click']}
                  menu={{ items: items(element) }}
                  placement="bottomLeft" >
          <Button shape="circle" type={'primary'}><FontAwesomeIcon size={'sm'} icon={faBars} /></Button>
        </Dropdown>
      ),
    },
    {
      key: 'sequence',
      dataIndex: 'sequence',
      title: 'Secuencia',
      width: '80px',
      render: (_, element) => element?.sequence
        ? element?.sequence
        : 'N/A'
    },
    {
      title: "# Items",
      dataIndex: "vehicleCount",
      key: "vehicleCount",
      width: '90px',
      render: (_, element) => element?.detalleExport ? element?.detalleExport?.length : 0
    },
    {
      key: 'colorCono',
      dataIndex: 'colorCono',
      title: 'Color de Cono',
      width: '110px',
      render: (_, element) => !element?.colorCono
        ? '---'
        : <div>
          <p className="mb-0"
             style={{
               padding: '0.2rem 0.6rem',
               borderRadius: '4px',
               color: 'white',
               background: element?.colorCono?.codigo
             }}>{element?.colorCono?.name}</p>
        </div>
    },
    {
      title: "Coneado",
      dataIndex: "conePlaced",
      key: "conePlaced",
      width: '100px',
      render: (text, record) =>
        record?.conePlaced ? (
          <Tag color="green">SI</Tag>
        ) : (
          <Tag color="red">NO</Tag>
        ),
    },
    {
      key: 'nameContainer',
      dataIndex: 'nameContainer',
      title: 'Contenedor',
      width: '200px',
      render: (_, element) => element?.container?.nameContainer
    },
    {
      key: 'carrier',
      dataIndex: 'carrier',
      title: 'Naviera',
      width: '250px',
      render: (_, element) => !element?.carrier
        ? '---'
        : <div>
          <p className="mb-0">{element?.carrier?.oceanCarrierName}</p>
        </div>
    },
    {
      key: 'aduana',
      dataIndex: 'aduana',
      title: 'Destino',
      width: '200px',
      render: (_, element) => !element.aduana
        ? '---'
        : <div>
          <p className="mb-0">{element?.aduana?.countryName}</p>
          <p className="mb-0">{element?.aduana?.nameAduana}</p>
        </div>
    },
    {
      key: 'containerNumber',
      dataIndex: 'containerNumber',
      title: '# de Contenedor',
      width: '180px',
      render: (_, element) => element?.containerNumber
    },
    {
      key: 'sealNumber',
      dataIndex: 'sealNumber',
      title: '# de Sello',
      width: '180px',
      render: (_, element) => element?.sealNumber
    },
    {
      key: 'state',
      dataIndex: 'state',
      title: 'Estado',
      width: '120px',
      render: (_, element) => (
        <>
          <ShowWhen show={element?.state === exportationContainerStateEnum.creado}>
            <div className="badge bg-primary">{element?.state}</div>
          </ShowWhen>
          <ShowWhen show={element?.state === exportationContainerStateEnum.asignado}>
            <div className="badge bg-info">{element?.state}</div>
          </ShowWhen>
          <ShowWhen show={element?.state === exportationContainerStateEnum.cargado}>
            <div className="badge bg-success">CARGADO</div>
          </ShowWhen>
        </>
      )
    },
    {
      title: "Vessel Name/Flag",
      dataIndex: "captain",
      key: "vesselNameFlag",
      width: '140px',
      render: (_, element) => element?.exportation?.vesselNameFlag
    },
    {
      title: "Voyage Identification",
      dataIndex: "captain",
      key: "voyageIdentification",
      width: '140px',
      render: (_, element) => element?.exportation?.voyageIdentification
    },
    {
      title: "Capitán",
      dataIndex: "captain",
      key: "captain",
      width: '140px',
    },
    {
      title: "Trucker",
      dataIndex: "trucker",
      key: "trucker",
      width: '140px',
      render: (_, element) => element?.exportation ? element?.exportation?.preCarriageBy?.nombre : '---'
    },
    {
      title: "Hora de Carga",
      dataIndex: "loadedAt",
      key: "loadedAt",
      width: '140px',
      render: (_, element) => element?.loadedAt
        ? <>
          <p className="mb-0">{moment(element?.loadedAt).format('DD-MM-yyyy')}</p>
          <p className="mb-0">{moment(element?.loadedAt).format('HH:mm:ss')}</p>
        </>
        : '---',
    }
  ];

  const handleMarcarTerminado = async record => {
    try {
      const input = {
        loadedBy: currentUser.fullName,
        loadedNotes: ''
      };

      setLoading(true);
      await updateExportationContainerLoaded(record?.idExportationContainer, input);
      setLoading(false);

      successMessage("Hora de carga editada con exito");
      if (onUpdate) onUpdate();
    } catch (ex) {
      errorMessage(ex.toString());
    }
  };

  const handleConedExportationContainer = async record => {
    try {
      const input = {
        ...record,
        werehouses: null, // null para que no modifique la lista de detalleExport asociados
        conePlaced: !record?.conePlaced,
      };

      setLoading(true);
      const resp = await updateExportationContainer(record.idExportationContainer, input);
      setLoading(false);

      if (resp?.conePlaced) {
        successMessage("Coneado con exito");
      } else {
        warningMessage("Desconado con exito");
      }

      if (onUpdate) onUpdate();
      setOpen(false);
      setDataExportationDrawer(undefined);
    } catch (ex) {
      errorMessage(ex.toString());
    }
  }

  return(
    <>
      <Table size={'small'}
             loading={loading}
             columns={columns}
             scroll={{x: 1300, y: 600}}
             dataSource={exportationContainers}
             expandable={{
               expandedRowRender: (record) => {
                 return(
                   <div>
                     <p className="mb-0 fw-bold">Notas de Carga:</p>
                     <p className="mb-0">{record.loadedNotes}</p>
                     <hr/>
                     <div className="d-none d-md-block">
                       <DetalleExportationTable detalleExportations={record.detalleExport} />
                     </div>
                     <div className="d-block d-md-none">
                       {
                         record.detalleExport.map((detalle, index) => (
                           <div key={index}><DetalleExportInfo detalleExport={detalle} /><hr/></div>
                         ))
                       }
                     </div>
                   </div>
                 )
               }
             }}
             pagination={{
               position: ["bottomRight"],
               total: totalElements,
               showSizeChanger: true,
               showTotal: total => `${total} elementos`,
               current: filters.currentPage,
               pageSize: filters.pageCount,
               pageSizeOptions: [10, 20, 50, 100, 150, 200, 500],
               onChange: onPageChange
             }}
             footer={() => {
               // motrar la suma de la columna de total de vehiculos (vehicleCount)
               const total = exportationContainers?.reduce(
                 (acc, item) => acc + (item?.detalleExport ? item?.detalleExport?.length : 0), 0
               );

               return (
                 <div className="d-flex align-items-center">
                   <p className="my-2 fw-bold">TOTAL DE VEHÍCULOS: {total}</p>
                 </div>
               );
             }}/>

      <DetailExportationDrawer open={open}
                               isLoading={loading}
                               onClose={() => {
                                 setOpen(false);
                                 setDataExportationDrawer(undefined);
                               }}
                               conear={() => handleConedExportationContainer(dataExportationDrawer)}
                               exportationContainer={dataExportationDrawer} />
    </>
  );
}

export default ExportationContainerTable;
