

import environment, {server} from '../environments/environment';


export const getTracking = async (lote) => {
    const response = await fetch(`${server}Tracking/${lote}`);
    const data = await response.json();
    return data;
};
