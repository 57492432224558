import {Select, Table, Button} from "antd";
import {useEffect, useState} from "react";
import {clientsActivity} from "../Servicios/ClienteService";
import moment from "moment";
import InputSearch from "../Componentes/InputSearch/InputSearch";
import {showCountries} from "../Servicios/CombosService";
import {DownloadOutlined} from "@ant-design/icons";
import ShowWhen from "../Componentes/ShowWhen/ShowWhen";
import {validarPermisos} from "../Servicios/AuthService";
import {permisos} from "../utils/permisos";

const XLSX = require('xlsx');
const ClienteActividadPage = () => {
  const [loading, setLoading] = useState(false);

  const [clientes, setClientes] = useState([]);
  const [countries, setCountries] = useState([]);
  const [clientesFiltrados, setClientesFiltrados] = useState([]);

  const [mes, setMes] = useState(3);
  const [countryId, setCountryId] = useState(0);
  const [clientesActivos, setClientesActivos] = useState(0);
  const [clientesInactivos, setClientesInactivos] = useState(0);

  useEffect(() => {
    init(mes, countryId).then();
  }, []);

  const init = async (meses, countryId = 0) => {
    setLoading(true);
    const data = await clientsActivity(meses, countryId);

    if (countries.length === 0) {
      const dataCountries = await showCountries();
      setCountries(dataCountries.map(e => ({value: e.id, label: e.nameCountry})));
    }
    setLoading(false);

    if (data && data.status) {
      setClientes(data.list);
      setClientesFiltrados(data.list);

      setClientesActivos(data.list.filter(e => !!e.ultimaFechaSolicitud).length);
      setClientesInactivos(data.list.filter(e => !e.ultimaFechaSolicitud).length);
    }
  }

  const columns = [
    {
      key: 'nombreCliente',
      dataIndex: "nombreCliente",
      title: "Cliente",
      width: '300px',
    },
    {
      key: 'nombreImportadora',
      dataIndex: "nombreImportadora",
      title: "Importadora",
      width: '300px',
    },
    {
      key: 'nombreAsignacion',
      dataIndex: "nombreAsignacion",
      title: "Asignación",
      width: '240px',
    },
    {
      key: 'phoneNumber',
      dataIndex: "phoneNumber",
      title: "# Telefonico",
      width: '240px',
    },
    {
      key: 'email',
      dataIndex: "email",
      title: "Email",
      width: '280px',
    },
    {
      key: 'numeroSolicitudes',
      dataIndex: "numeroSolicitudes",
      title: "# Solicitudes",
      width: '120px',
    },
    {
      key: 'ultimaFechaSolicitud',
      dataIndex: "ultimaFechaSolicitud",
      title: "Ultima Solicitud Realizada",
      width: '220px',
      render: (value) => value
        ? <div>{moment(value).format('MMM DD, yyyy')}</div>
        : <div className="badge bg-danger">Inactivo</div>
    }
  ];

  const handleChange = async (mes, countryId) => {
    setMes(mes);
    setCountryId(countryId);

    await init(mes, countryId);
  }

  const handleSearch = event => {
    setClientesFiltrados(
      clientes.filter(e =>
        event === "" ||
        `${e.nombreCliente} ${e.nombreImportadora} ${e.nombreAsignacion} ${e.phoneNumber} ${e.email}`.toUpperCase().indexOf(event.toUpperCase()) !== -1)
    );
  }

  const exportToExcel = () => {

    const ws = XLSX.utils.json_to_sheet(clientesFiltrados.map(e => ({
      'Cliente': e.nombreCliente,
      'Importadora': e.nombreImportadora,
      'Asignación': e.nombreAsignacion,
      'Telefono': e.phoneNumber,
      'Email': e.email,
      'Solicitudes': e.numeroSolicitudes,
      'Ultima Solicitud': e.ultimaFechaSolicitud ? moment(e.ultimaFechaSolicitud).format('MMM DD, yyyy') : 'Inactivo'
    })));

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Clientes");
    XLSX.writeFile(wb, "Clientes.xlsx");
    
  };

  return(
    <>
      <div>
        <h5>Clientes</h5>
        <p className="mb-0"><span className="fw-bold">Total de clientes Activos:</span> {clientesActivos}</p>
        <p className="mb-0"><span className="fw-bold">Total de clientes Inactivos:</span> {clientesInactivos}</p>
      </div>
      <hr/>
      <div className="row">
        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
          <p className="mb-1">Filtrar por Pais</p>
          <Select className="w-100"
                  value={countryId === 0 ? 'Seleccionar opción' : countryId}
                  options={countries}
                  onChange={event => handleChange(mes, event)}></Select>
        </div>
        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
          <p className="mb-1">Filtrar por Fecha</p>
          <Select className="w-100"
                  value={mes}
                  options={[
                    { value: 3, label: 'Ultimos 3 meses' },
                    { value: 6, label: 'Ultimos 6 meses' },
                    { value: 9, label: 'Ultimos 9 meses' },
                    { value: 12, label: 'Ultimo año' }
                  ]}
                  onChange={event => handleChange(event, countryId)}></Select>
        </div>
      </div>
      <hr/>
      <div className="mb-3">
        <InputSearch placeholder={'Buscar por nombre de cliente, importadora, vendedor, telefono o email'}
                     doChange={handleSearch} />
      </div>
      <div className="d-flex align-items-center justify-content-end mb-3">
        <ShowWhen show={validarPermisos(permisos.CLIENTES_ACTIVIDAD_DESCARGAR)}>
          <Button size="sm" type="primary" onClick={exportToExcel} className="d-flex align-items-center justify-content-end gap-2">
            <DownloadOutlined /> Exportar a Excel
          </Button>
        </ShowWhen>
      </div>
      <Table size={'small'}
             scroll={{x: 1300, y: 600}}
             loading={loading}
             columns={columns}
             dataSource={clientesFiltrados} />
    </>
  );
}

export default ClienteActividadPage;
