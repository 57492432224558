import React, {useState, useEffect} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye, faPencil, faPenToSquare} from '@fortawesome/free-solid-svg-icons';
import {useNavigate, useParams} from "react-router-dom";
import {clienteSetDeudor, deleteClient, editClient, listClients} from "../../Servicios/ClienteService";
import Form from 'react-bootstrap/Form';
import 'styled-components';
import '../../hojas-de-estilo/style.css';
import {permisos} from "../../utils/permisos";
import {hasRol, validarPermisos} from "../../Servicios/AuthService";
import {Button, Col, Modal, Row, Table, Tabs, Tag, Tooltip} from "antd";
import {getUserSellers} from "../../Servicios/UsuarioService";
import {errorMessage, successMessage} from "../../utils/toast-message";
import {ClienteClassPay} from "../../shared/constants/cliente-class-pay.const";
import roles from "../../utils/roles";
import InputSearch from "../../Componentes/InputSearch/InputSearch";
import localStorageFilter from "../../shared/utils/local-storage-filter";
import ShowWhen from "../../Componentes/ShowWhen/ShowWhen";
import EliminarClienteBtn from "../EliminarClienteBtn";

interface ClienteFilters {
  pageCount: number;
  currentPage: number;
  search: string;
  clasificacion: number;
  deudor: number;
}

const ClienteTable = () => {
  const {id} = useParams();
  const navigate = useNavigate();

  const puedeEliminarCliente = validarPermisos(permisos.ELIMINAR_CLIENTE);
  const puedeModificarCliente = validarPermisos(permisos.MODIFICAR_CLIENTE);

  const [client, setClient] = useState([]);
  const [sellers, setSellers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalElements, setTotalElements] = useState(0);

  const [classPay, setClassPay] = useState(undefined);
  const [asignacionId, setAsignacionId] = useState(undefined);
  const [modificarCliente, setModificarCliente] = useState(undefined);

  const [filters, setFilters] = useState({
    pageCount: localStorageFilter(`cliente:filter:${id}`,'pageCount') ?? 10,
    currentPage: localStorageFilter(`cliente:filter:${id}`,'currentPage') ?? 1,
    search: localStorageFilter(`cliente:filter:${id}`,'search') ?? "",
    clasificacion: localStorageFilter(`cliente:filter:${id}`,'clasificacion') ?? 1,
    deudor: localStorageFilter(`cliente:filter:${id}`,'deudor') ?? -1,
  });

  const showData = async (filters: ClienteFilters) => {
    setIsLoading(true);
    const data = await listClients(id, 0, filters.currentPage, filters.pageCount, filters.search,
        "",
        0,
        filters.deudor === 1 ? 0 : filters.clasificacion,
        filters.deudor);
    setIsLoading(false);

    setClient(data.list);
    setTotalElements(data.totalElements);
  }

  useEffect(() => {
    showData(filters).then();

    const localFilters = localStorageFilter();
    if (JSON.stringify(filters) !== JSON.stringify(localFilters)) {
      localStorage.setItem(`cliente:filter:${id}`, JSON.stringify(filters));
    }
  }, [id, filters]);

  useEffect(() => {
    getUserSellers().then(data => setSellers(data));
  }, []);

  const handleClienteDeudor = async (element, deudor) => {
    setIsLoading(true);
    await clienteSetDeudor(element.id, deudor);
    setIsLoading(false);

    await showData(filters);
  }

  const handleEliminarCliente = async (cliente) => {
    setIsLoading(true);
    const data = await deleteClient(cliente.id);
    setIsLoading(false);

    if (data && data.status) {
      successMessage('El cliente ha sido eliminado');
      await showData(filters);
    } else {
      errorMessage(data.message);
    }
  }

  const columns = [
    {
      key: 'actions',
      dataIndex: 'actions',
      title: '',
      width: "170px",
      fixed: 'left',
      render: (_, element) => <div className="d-flex align-items-center justify-content-start gap-2">
        <Button onClick={() => navigate(`/seeProfileClient/${element?.id}`)} type="primary" >
          <FontAwesomeIcon icon={faEye}/>
        </Button>

        <ShowWhen show={puedeModificarCliente}>
          <Button onClick={() => navigate(`/editarCli/${element?.id}`)} type="primary">
            <FontAwesomeIcon icon={faPenToSquare}/>
          </Button>
        </ShowWhen>

        <ShowWhen show={puedeEliminarCliente}>
          <EliminarClienteBtn smSize={true} onCancelar={() => handleEliminarCliente(element)} />
        </ShowWhen>
      </div>
    },
    {
      key: 'deudor',
      dataIndex: 'deudor',
      title: 'Estado',
      width: "150px",
      render: (value, element) => {
        return(
            <div className="d-flex align-items-start gap-2">
              {
                filters.clasificacion === 1
                    ? <Tag color="green">ACTIVO</Tag>
                    : filters.clasificacion === 2
                        ? <Tag color="orange">PRECAUCIÓN</Tag>
                        : filters.clasificacion === 3
                            ? <Tag color="red">RIESGO</Tag>
                            : filters.clasificacion === 4
                                ? <Tag color="default">RECUPERAR</Tag>
                                : <Tag color="black">DEUDOR</Tag>
              }
              <ShowWhen show={puedeModificarCliente && hasRol(roles.COBROS, roles.ADMIN)}>
                {
                  value
                      ? <Tooltip title={'Marcar como No Deudor'}>
                          <Button size={'small'} type={'default'}
                                  onClick={() => handleClienteDeudor(element, !value)}>
                            <FontAwesomeIcon icon={faPencil}/>
                          </Button>
                      </Tooltip>
                      : <Tooltip title={'Marcar como Deudor'}>
                          <Button size={'small'} type={'default'} danger
                                  onClick={() => handleClienteDeudor(element, !value)}>
                            <FontAwesomeIcon icon={faPencil}/>
                          </Button>
                      </Tooltip>
                }
              </ShowWhen>
            </div>
        )
      }
    },
    {
      key: 'segmentoName',
      dataIndex: 'segmentoName',
      title: 'Segmento',
      width: "160px",
    },
    {
      key: 'nombreCompleto',
      dataIndex: 'nombreCompleto',
      title: 'Cliente',
      width: "250px",
    },
    {
      key: 'contNum',
      dataIndex: 'contNum',
      title: 'Numero Contacto',
      width: "250px",
    },
    {
      key: 'importadoraName',
      dataIndex: 'importadoraName',
      title: 'Nombre Importadora',
      width: "300px",
    },
    {
      key: 'classPay',
      dataIndex: 'classPay',
      title: 'Clasificación de Pago',
      render: (_, row) => <div className="d-flex align-items-center justify-content-start gap-3">
        <ShowWhen show={puedeModificarCliente && hasRol(roles.COBROS, roles.SUPERVISOR, roles.ADMIN)}>
          <FontAwesomeIcon icon={faPencil}
                           className="cursor-pointer"
                           onClick={() => {
                             setClassPay(row.classPay == null || row.classPay === '' ? "0" : row.classPay);
                             setModificarCliente(row);
                           }} />
        </ShowWhen>
        <p className="mb-0">
          {row.classPay}
        </p>
      </div>,
      width: "200px",
    },
    {
      key: 'asignacionVendedor',
      dataIndex: 'asignacionVendedor',
      title: 'Asignación',
      render: (_, row) => <>
        <div className="d-flex align-items-center justify-content-start gap-3">
          <ShowWhen show={puedeModificarCliente && hasRol(roles.SUPERVISOR, roles.ADMIN)}>
            <FontAwesomeIcon icon={faPencil}
                             className="cursor-pointer"
                             onClick={() => {
                               setAsignacionId(row.asignacionId ?? "0");
                               setModificarCliente(row);
                             }} />
          </ShowWhen>
          <p className="mb-0">
            {row.asignacionVendedor ? row.asignacionVendedor.fullName : row.asignacion }
          </p>
        </div>
      </>,
      width: "200px",
    },
    {
      key: 'countryName',
      dataIndex: 'countryName',
      title: 'Pais',
      width: "200px",
    },
    {
      key: 'aduanaName',
      dataIndex: 'aduanaName',
      title: 'Aduana Pref',
      width: "200px",
    }
  ];

  const handlePageChange = (newPage, pageCount) => {
    setFilters({
      ...filters,
      currentPage: newPage,
      pageCount: pageCount
    });
  };

  const handleFilterText = (value) => {
    setFilters({
      ...filters,
      currentPage: 1,
      search: value
    });
  }

  const handleSaveAsignacion = async () => {
    const input = {
      ...modificarCliente,
      asignacionId: +asignacionId,
    }

    setIsLoading(true);
    const data = await editClient(modificarCliente.id, input);
    setIsLoading(false);

    if (data && data.status) {
      showData(filters).then();
      setAsignacionId(undefined);
      setModificarCliente(undefined);
    } else {
      errorMessage(data.message);
    }
  }

  const handleSaveClassPay = async () => {
    const input = {
      ...modificarCliente,
      classPay: classPay,
      apellido1: modificarCliente.apellido1 ?? "",
    }

    setIsLoading(true);
    const data = await editClient(modificarCliente.id, input);
    setIsLoading(false);

    if (data && data.status) {
      showData(filters).then();
      setClassPay(undefined);
      setModificarCliente(undefined);
    } else {
      errorMessage(data.message);
    }
  }

  const tabs = [
    {
      key: 1,
      label: <Tag className="m-0" color="green">ACTIVOS</Tag>
    },
    {
      key: 2,
      label: <Tag className="m-0" color="orange">PRECAUCIÓN</Tag>
    },
    {
      key: 3,
      label: <Tag className="m-0" color="red">RIESGO</Tag>
    },
    {
      key: 4,
      label: <Tag className="m-0" color="default">RECUPERAR</Tag>
    },
    {
      key: 5,
      label: <Tag className="m-0" color="black">DEUDORES</Tag>
    },
  ];

  const handleChangeTab = tab => {
    if (tab === 5) {
      setFilters({
        ...filters,
        deudor: 1,
        clasificacion: tab
      });
    } else {
      setFilters({
        ...filters,
        deudor: 0,
        clasificacion: tab
      });
    }
  }

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} md={24} lg={24} xl={24}>
        <Tabs centered items={tabs} activeKey={filters.clasificacion} onChange={handleChangeTab} />
      </Col>
      <Col xs={24} md={24} lg={24} xl={24}>
        <InputSearch placeholder={'Buscar cliente'} doChange={handleFilterText} initialValue={filters.search} />
      </Col>
      <Col xs={24} md={24} lg={24} xl={24}>
        <Table columns={columns}
               dataSource={client}
               size={'small'}
               scroll={{x: 1600}}
               loading={isLoading}
               pagination={{
                 position: ["bottomRight"],
                 total: totalElements,
                 showSizeChanger: true,
                 showTotal: total => `${total} elementos`,
                 current: filters.currentPage,
                 pageSize: filters.pageCount,
                 pageSizeOptions: [20, 50, 100, 150, 200, 500],
                 onChange: handlePageChange
               }} />
      </Col>

      <Modal open={!!modificarCliente && !!asignacionId}
             title={'Modificar Asignación'}
             onCancel={() => {
               setAsignacionId(undefined);
               setModificarCliente(undefined);
             }}
             okButtonProps={{style: {display: "none"}}}
             cancelButtonProps={{style: {display: "none"}}}>
        <Form.Select className="my-3"
                     onChange={(event) => {
                       setAsignacionId(event.target.value);
                     }}
                     name='asignacionId'
                     value={asignacionId ?? "0"}>
          <option disabled value="0">Seleccione</option>
          {
            sellers.map(e => <option value={e.idUser}>{e.firstName} {e.lastName}</option>)
          }
        </Form.Select>
        <div className="w-100 text-end">
          <Button onClick={handleSaveAsignacion}>Guardar</Button>
        </div>
      </Modal>

      <Modal open={!!modificarCliente && !!classPay}
             title={'Modificar Clasificación de Pago'}
             onCancel={() => {
               setClassPay(undefined);
               setModificarCliente(undefined);
             }}
             okButtonProps={{style: {display: "none"}}}
             cancelButtonProps={{style: {display: "none"}}}>
        <Form.Select className="my-3"
                     onChange={(event) => {
                       setClassPay(event.target.value);
                     }}
                     name='classPay'
                     value={classPay ?? "0"}>
          <option disabled value="0">Seleccione</option>
          {
            ClienteClassPay.map(e => <option value={e.value}>{e.label}</option>)
          }
        </Form.Select>
        <div className="w-100 text-end">
          <Button onClick={handleSaveClassPay}>Guardar</Button>
        </div>
      </Modal>
    </Row>
  );
}

export default ClienteTable;