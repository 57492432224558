import {Button, Row, Col} from "antd";
import {useState, useEffect} from "react";
import Spinner from "../../../shared/spinner";
import {useNavigate} from "react-router-dom";
import useCurrentUser from "../../../Hooks/UserHook";
import ExportationTable from "../ExportationTable";
import InputSearch from "../../../Componentes/InputSearch/InputSearch";
import {ExportationStateEnum} from "../../../shared/enums/exportation-state.enum";
import {getExportations} from "../../../Servicios/ExportationServices";

const AsignacionBookingPage = () => {
  const navigate = useNavigate();
  const currentUser = useCurrentUser();

  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [exportations, setExportations] = useState([]);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  useEffect(() => {
    if (currentUser?.idubc) {
      initExportations().then();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?.idubc, pagination.current]);

  const initExportations = async () => {
    setLoading(true);
    const data = await getExportations(currentUser?.idubc, pagination.current, pagination.pageSize, search, ExportationStateEnum.Creado);
    setLoading(false);
    setExportations(data.list.map((e, index) => {
      return {
        key: index,
        ...e,
      }
    }));

    setPagination({
      ...pagination,
      total: data?.totalElements,
    });
  }

  const handleTableChange = (pagination) => {
    setPagination((prevState) => (
      {
        ...prevState,
        current: pagination.current,
        pageSize: pagination.pageSize,
      }
    ));
  }

  const handleSearch = async (value) => {
    setSearch(value);
    setPagination({...pagination, current: 1});
  }

  return (
    <>
      <Spinner loading={loading}>
        <Row gutter={[16, 24]}>
          <Col
            span={24}
            style={{display: "flex", justifyContent: "space-between"}}
          >
            <h4>Control de Booking</h4>

            <Button
              type="primary"
              onClick={async () => {
                await navigate("/exportation/create");
              }}
            >
              Registrar Nueva Exportacion
            </Button>
          </Col>
        </Row>

        <hr/>
        <div className="mb-3">
          <InputSearch placeholder={'Search by shipping name or booking number'}
                       doChange={handleSearch}/>
        </div>
        <ExportationTable exportations={exportations}
                          pagination={pagination}
                          onPageChange={handleTableChange}
                          onRefreshTable={() => initExportations(pagination.current, pagination.pageSize, search)}
                          onEditExportation={() => {
                            initExportations(pagination.current, pagination.pageSize, search).then();
                          }}/>
      </Spinner>
    </>
  );
};

export default AsignacionBookingPage;
