import {getVehicleInStock} from "../Servicios/VehicleService";
import {useEffect, useState} from "react";
import Spinner from "../shared/spinner";
import {Table, Tabs, Tag} from "antd";
import localStorageFilter from "../shared/utils/local-storage-filter";
import InputSearch from "../Componentes/InputSearch/InputSearch";
import useCurrentUser from "../Hooks/UserHook";
import {hasRol} from "../Servicios/AuthService";
import roles from "../utils/roles";
import ShowWhen from "../Componentes/ShowWhen/ShowWhen";

interface Filters {
  pageCount: number;
  currentPage: number;
  search: string;
  origenId: number;
}

const Inventario = () => {
  const currentUser = useCurrentUser();
  const showTab = hasRol(roles.ADMIN, roles.SUPERVISOR);

  const [loading, setLoading] = useState(false);

  const [vehiculos, setVehiculos] = useState([]);
  const [totalElements, setTotalElements] = useState(0);

  const [filters, setFilters] = useState({
    pageCount: localStorageFilter('inventario:filter', 'pageCount') ?? 100,
    currentPage: localStorageFilter('inventario:filter', 'currentPage') ?? 1,
    search: localStorageFilter('inventario:filter', 'search') ?? "",
    origenId: localStorageFilter('inventario:filter', 'origenId') ?? currentUser?.idubc,
  });

  useEffect(() => {
    if (currentUser) {
      if (filters.origenId) {
        init(filters).then();
      } else {
        setFilters({
          ...filters,
          origenId: +currentUser.idubc
        });
      }
    }

    const localFilters = localStorageFilter();
    if (JSON.stringify(localFilters) !== JSON.stringify(filters)) {
      localStorage.setItem('inventario:filter', JSON.stringify(filters));
    }
  }, [filters, currentUser]);

  const init = async (filters: Filters) => {
    setLoading(true);
    const data = await getVehicleInStock(filters.currentPage, filters.pageCount, filters.search, filters.origenId);
    setLoading(false);

    setVehiculos(data.list);
    setTotalElements(data.totalElements);
  }

  const columns = [
    {
      key: 'lote',
      dataIndex: 'lote',
      title: 'Lote'
    },
    {
      key: 'vin',
      dataIndex: 'vin',
      title: 'VIN'
    },
    {
      key: 'vehiculoName',
      dataIndex: 'vehiculoName',
      title: 'Descripción'
    },
    {
      key: 'color',
      dataIndex: 'color',
      title: 'Color'
    },
    {
      key: 'country',
      dataIndex: 'country',
      title: 'País'
    },
    {
      key: 'aduana',
      dataIndex: 'aduana',
      title: 'Destino'
    },
    {
      key: 'titulo',
      dataIndex: 'titulo',
      title: 'Título',
      render: (value) => value
        ? <Tag color={'success'}>SI</Tag>
        : <Tag color={'error'}>SI</Tag>
    }
  ];

  const handlePageChange = (page, pageSize) => {
    setFilters({
      ...filters,
      currentPage: page,
      pageCount: pageSize,
    });
  }

  const handleChangeSearch = (value) => {
    setFilters({
      ...filters,
      search: value,
      currentPage: 1,
    })
  }

  const items = [
    { key: 1, label: 'Florida' },
    { key: 2, label: 'California' }
  ];

  const handleChangeTab = (tab) => {
    setFilters({
      ...filters,
      origenId: tab,
      currentPage: 1,
    });
  }

  return(
    <Spinner loading={loading}>
      <h5 className="mb-0">Inventario de Vehículos</h5>
      <hr/>
      <div className="mb-3">
        <ShowWhen show={showTab}>
          <Tabs activeKey={filters.origenId} items={items} onChange={handleChangeTab} />
        </ShowWhen>
        <InputSearch placeholder={'buscar por vin o por lote'} doChange={handleChangeSearch} />
      </div>
      <Table size={'small'}
             columns={columns}
             dataSource={vehiculos}
             pagination={{
               position: ["bottomRight"],
               total: totalElements,
               showSizeChanger: true,
               showTotal: total => `${total} elementos`,
               current: filters.currentPage,
               pageSize: filters.pageCount,
               pageSizeOptions: [20, 50, 100, 150, 200, 500],
               onChange: handlePageChange
             }} />
    </Spinner>
  );
}

export default Inventario;
