import React, { useEffect, useState } from "react";
import {
  Card,
  Avatar,
  List,
  Steps,
  Typography,
  Row,
  Col,
  Layout,
  Alert,
  Button,
} from "antd";

import {
  UserOutlined,
  PhoneOutlined,
  MailOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
const { Step } = Steps;
const { Content, Header } = Layout;
const UserCard = ({data}) => (
  <Card style={{ width: "100%", marginBottom: 20 }}>
    <Card.Meta
      avatar={<Avatar size={64} 
      icon={data?.vehicle?.imageFront ? <img src={data?.vehicle?.imageFront} alt="Vehicle" /> : <UserOutlined />}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      />}
      title={`${data?.vehicle?.year} ${data?.vehicle?.make} ${data?.vehicle?.model}`}
      description={`${data?.vehicle?.vin}`}
    />
    <div style={{ marginTop: 20 }}>
      <Typography.Paragraph>Lote# {data?.lote}</Typography.Paragraph>
      <Typography.Paragraph>
        Vehiculo: {data?.vehicle?.year} {data?.vehicle?.make} {data?.vehicle?.model}
      </Typography.Paragraph>
      <Typography.Paragraph>
        VIN: {data?.vehicle?.vin}
      </Typography.Paragraph>
      <Typography.Paragraph>
        {/* FALTA QUE ME PASE ESTE VALOR EN EL SERVICIO */}
        Fotos:  No hay fotos disponibles 
      </Typography.Paragraph>
      <hr />
      <div
        style={{
          marginTop: 20,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography.Paragraph>
          <strong>Datos de origen</strong>
        </Typography.Paragraph>
      </div>
      <Typography.Paragraph>Oficina: {data?.quote?.origen?.name}</Typography.Paragraph>
      <Typography.Paragraph>Direccion: {data?.quote?.origen?.address}</Typography.Paragraph>
      <Typography.Paragraph> {data?.quote?.origen?.city}, {data?.quote?.origen?.state}</Typography.Paragraph>
      <hr />
      <div
        style={{
          marginTop: 20,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography.Paragraph>
          <strong>Datos de destino</strong>
        </Typography.Paragraph>
      </div>
      <Typography.Paragraph>Pais: EL SALVADOR</Typography.Paragraph>
      <Typography.Paragraph>Aduana: NEJAPA</Typography.Paragraph>
      <Typography.Paragraph>
        Cliente: IMPORTADORA DE AUTOS CHARLY
      </Typography.Paragraph>
      <Typography.Paragraph>
        Direccion: VILLA CARMELA MIXCO, SAN SALVADOR
      </Typography.Paragraph>
    </div>
  </Card>
);

const ItineraryItem = ({ item }) => (
  <Step
    title={item.title}
    description={
      <div>
        <Typography.Text>{item.date}</Typography.Text>
        <Typography.Paragraph>{item.description}</Typography.Paragraph>
      </div>
    }
  />
);

const NotificationItem = ({ item }) => (
  <List.Item>
    <List.Item>
      <Typography.Text>{item.text}</Typography.Text>
      <Typography.Text>{item.time}</Typography.Text>
    </List.Item>
  </List.Item>
);

const DetailTracking = () => {
  const [tracking, setTracking] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const data = location.state?.data;
  console.log("Data:", data);

  const itineraryData = Array(5).fill({
    title: "Meeting with partners",
    date: "10/11/2018",
    description: "Discuss the new partnership agreement",
  });

  const notifications = Array(5).fill({
    text: "You have a new message",
    time: "10/11/2018",
  });

  const description = "This is a description.";

  const items = [
    {
      title: "Fecha de solicitud ",
      description: "07-07-2024",
    },
    {
      title: "Fecha de confirmacion",
      description: "07-07-2024",
    },
    {
      title: "Fecha estimada de retiro de subasta",
      description: "COPART - LAS VEGAS, NV",
      date: "07-07-2024",
    },

    {
      title: "Fecha de salida de puerto",
      description: "MATUS INTERNATIONAL - LONG BEACH, CA",
    },
    {
      title: "Fecha de llegada a destino",
      description,
    },
  ];

  return (
    <Layout>
      <Header style={{ padding: 2 }}>
        <Typography.Title
          level={1}
          className="p-2 text-center"
          style={{ color: "white" }}
        >
          Tracking Detail
        </Typography.Title>
      </Header>

      <Content style={{ padding: 24, background: "#fff" }}>
        <Alert
          message={
            <Typography.Text strong>
              Gracias por confiar tu carga con Matus International, te
              compartimos toda la informacion del estado de tu vehiculo.
            </Typography.Text>
          }
          type="success"
          showIcon
        />

        <Row gutter={16} style={{ marginTop: 20 }}>
          <Col xs={24} md={8}>
            <UserCard data={data} />
            <Button
              type="primary"
              onClick={() => {
                navigate("/SearchTracking");
              }}
              block
            >
              Buscar otro lote
            </Button>
          </Col>
          <Col xs={24} md={8}>
            <Card title="Itinerary">
              <Steps direction="vertical" current={2} items={items} />
            </Card>
          </Col>
          <Col xs={24} md={8}>
            <Card title="Notifications">
              <List
                dataSource={notifications}
                renderItem={(item, index) => (
                  <NotificationItem key={index} item={item} />
                )}
              />
            </Card>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default DetailTracking;
