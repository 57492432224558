import WarehouseForm from "./WarehouseForm";
import {addWR} from "../Servicios/WerehouseService";
import Spinner from "../shared/spinner";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {successMessage} from "../utils/toast-message";
import {WarehouseProvider} from "../contexts/WarehouseContext";

const WarehouseAutoPartsPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    try {
      const input = {
        ...values,
        subastaId: +values.subastaId,
        importadoraId: +values.importadoraId,
        quantity: +values.quantity,
        schedulebId: +values.schedulebId,
        stateId: +values.stateId,
        typeMerchId: +values.typeMerchId,
        originId: +values.originId,
        licenceTypeId: +values.licenceTypeId,
        expoCodeId: +values.expoCodeId,
        aduanaId: +values.aduanaId,
      }

      setLoading(true);
      const data = await addWR(input);
      setLoading(false);

      if (data) {
        successMessage('Warehouse creado con exito');
        setTimeout(() => {
          return navigate(`/viewWereHouse/${data.werehouseId}`);
        }, 1000);
      }
    } catch (ex) {
      setLoading(false);
    }
  }

  return(
    <WarehouseProvider>
      <Spinner loading={loading}>
        <WarehouseForm hasVehicle={false} onSubmit={handleSubmit} />
      </Spinner>
    </WarehouseProvider>
  );
}

export default WarehouseAutoPartsPage;
