import {Button, Col, Modal, Row, Tag} from "antd";
import {useEffect, useState} from "react";
import {errorMessage} from "../../../utils/toast-message";
import ExportationContainerForm from "./ExportationContainerForm";
import Spinner from "../../../shared/spinner";
import localStorageFilter from "../../../shared/utils/local-storage-filter";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import ExportationContainerTable from "./ExportationContainerTable";
import {getExportationContainer} from "../../../Servicios/ExportationContainerService";
import useCurrentUser from "../../../Hooks/UserHook";
import {getDateForMilliseconds} from "../../../utils/date.helper";
import moment from "moment/moment";
import {getListDateLoadingProgram} from "../../../Servicios/ExportationServices";
import {validarPermisos} from "../../../Servicios/AuthService";
import {permisos} from "../../../utils/permisos";
import ShowWhen from "../../../Componentes/ShowWhen/ShowWhen";

class Filters {
  currentPage: number;
  pageCount: number;
  loadingDate: Date | undefined;
}

const ExportationContainerPage = () => {
  const currentUser = useCurrentUser();

  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const [totalElements, setTotalElements] = useState(0);
  const [loadingProgramDates, setLoadingProgramDates] = useState([]);
  const [exportationContainers, setExportationContainers] = useState([]);
  const [updateExportationContainer, setUpdateExportationContainer] = useState(undefined);

  const [filters, setFilters] = useState({
    currentPage: localStorageFilter('exportationContainer:filter', 'currentPage') ?? 1,
    pageCount: localStorageFilter('exportationContainer:filter', 'pageCount') ?? 10,
    loadingDate: localStorageFilter('exportationContainer:filter', 'loadingDate', true),
  });

  useEffect(() => {
    if (!currentUser) return;
    const localFilters = localStorageFilter();

    if (JSON.stringify(filters) !== JSON.stringify(localFilters)) {
      localStorage.setItem('exportationContainer:filter', JSON.stringify(filters));
    }

    init(filters).then();
  }, [filters, currentUser]);

  useEffect(() => {
    if (!currentUser) return;
    fetchLoadingProgramDates().then();
  }, [currentUser]);

  const fetchLoadingProgramDates = async () => {
    const list = await getListDateLoadingProgram(currentUser.idubc);
    setLoadingProgramDates(list);
  };

  const init = async (filters: Filters) => {
    try {
      setLoading(true);
      const data = await getExportationContainer(
        0,
        filters.loadingDate ? filters.loadingDate.getTime() : 0,
        filters.currentPage,
        filters.pageCount,
        "",
        0,
        0,
        currentUser?.idubc ?? 0
      );
      setLoading(false);

      setTotalElements(data.totalElements);
      setExportationContainers(data.list.map((e, index) => {
        return {
          key: index,
          ...e,
        }
      }));
    } catch (ex) {
      setLoading(false);
      errorMessage(`${ex.toString()}`);
    }
  }

  const handlePageChange = (page, pageSize) => {
    setFilters({
      ...filters,
      currentPage: page,
      pageCount: pageSize,
    });
  };

  const handleAddExportationContainer = () => {
    setShowForm(true);
  }

  const handleUpdateExportationContainer = (element) => {
    setShowForm(true);
    setUpdateExportationContainer(element);
  }

  const handleSelectDate = async item => {
    const currentDate = getDateForMilliseconds(item?.date);
    setFilters({
      ...filters,
      loadingDate: currentDate
    });
  };

  const handleUpdate = async () => {
    await fetchLoadingProgramDates();
    await init(filters);
  }

  return(
    <>
      <Spinner loading={loading}>
        <div className="d-flex align-items-center justify-content-between flex-wrap mb-3">
          <h3 className="mb-3 mb-sm-0">Planificación de Contenedores</h3>
          <ShowWhen show={validarPermisos(permisos.PLANIFICACION_CONTENEDOR_AGREGAR)}>
            <Button type={'primary'} onClick={handleAddExportationContainer}>
              <FontAwesomeIcon className="me-2" size={'sm'} icon={faPlus} /> Agregar Contenedor
            </Button>
          </ShowWhen>
        </div>
        <Row>
          <Col xs={9} sm={7} md={6} lg={5} xl={4} className="pe-1 border-top border-end pt-2">
            <p className="fw-bolder">Filtrar por Fecha de Carga</p>
            <div className="overflow-scroll pe-3 h-75">
              {
                loadingProgramDates.map((item) => {
                  const date = getDateForMilliseconds(item.date).getTime();
                  const current = filters.loadingDate
                    ? filters.loadingDate.getTime()
                    : 0;

                  return (
                    <Tag className="w-100 py-2 px-3 mb-3 me-0 cursor-pointer"
                         color={date === current ? "green" : ""}
                         onClick={() => handleSelectDate(item)}>
                      <div key={item?.date}
                           className="d-flex align-items-center justify-content-between flex-wrap">
                        <p className="mb-0 me-3 fw-bolder">{moment(item.date).format("MM-DD-YYYY")}</p>
                        <Tag className="m-0"><p className="mb-0 fw-bold">{item.count}</p></Tag>
                      </div>
                    </Tag>
                  );
                })
              }
            </div>
          </Col>
          <Col xs={15} sm={17} md={18} lg={19} xl={20}>
            <ExportationContainerTable filters={filters}
                                       currentUser={currentUser}
                                       totalElements={totalElements}
                                       exportationContainers={exportationContainers}
                                       onUpdate={handleUpdate}
                                       onPageChange={handlePageChange}
                                       onUpdateExportationContainer={handleUpdateExportationContainer} />
          </Col>
        </Row>

        <Modal width={'80%'}
               open={showForm}
               title={'Asignar contenedor'}
               closable={false}
               okButtonProps={{style: {display: 'none'}}}
               cancelButtonProps={{style: {display: 'none'}}}>
          <ExportationContainerForm idOrigen={currentUser?.idubc}
                                    editable={true}
                                    exportationContainer={updateExportationContainer}
                                    onCancel={() => {
                                      setShowForm(false);
                                      setUpdateExportationContainer(undefined);
                                      init(filters).then();
                                    }}
                                    onSubmit={() => {
                                      setShowForm(false);
                                      setUpdateExportationContainer(undefined);
                                      init(filters).then();
                                      fetchLoadingProgramDates().then();
                                    }} />
        </Modal>
      </Spinner>
    </>
  );
}

export default ExportationContainerPage;
