import React, {useState, useEffect} from "react";
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faPen} from "@fortawesome/free-solid-svg-icons";
import Form from "react-bootstrap/Form";
import DataTable from "react-data-table-component";
import "styled-components";

import {Link, useNavigate, useParams} from "react-router-dom";
import Container from "react-bootstrap/esm/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {showWR} from "../Servicios/WerehouseService";
import SpinnerTable from "../Componentes/SpinnerTable";
import InputSearch from "../Componentes/InputSearch/InputSearch";

const TableWerehouse = () => {
  const navigate = useNavigate();
  const [werehouses, setWerehouses] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const {id} = useParams();

  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1); // Obtén el mes actual (agregamos +1 ya que los meses van de 0 a 11)
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear()); // Obtén el año actual
  const [mesesDelAño, setMesesDelAño] = useState([]);

  const [pageCount, setPageCount] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalElements, setTotalElements] = useState(0);

  const [filterText, setFilterText] = useState("");

  const showWerehouse = async (currentPage = 0, pageCount = 0, search = "") => {
    setIsLoading(true);
    if (!currentMonth || !currentYear) {
      console.error("El mes y/o el año están vacíos o nulos.");
      setIsLoading(false); // Asegúrate de detener la animación de carga
      return; // Detenemos la ejecución de la función
    }

    const data = await showWR(id, currentMonth, currentYear, currentPage, pageCount, search);
    setIsLoading(false);

    if (data.status) {
      setWerehouses(data.list);
      setTotalElements(data.totalElements);
    }
  };

  useEffect(() => {
    showWerehouse(currentPage, pageCount, filterText).then();
  }, [id, currentMonth, currentYear, filterText]);

  useEffect(() => {
    const meses = Array.from({length: 12}, (_, index) => ({
      value: index + 1,
      label: obtenerNombreMes(index + 1),
    }));

    setMesesDelAño(meses); // Almacenar los meses en el estado
  }, []);

  const obtenerNombreMes = (mes) => {
    const nombresMeses = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    return nombresMeses[mes - 1];
  };

  // Obtener el año actual
  const añoActual = new Date().getFullYear();

  // Obtener una lista de años desde un año antes del actual hasta un año después
  const añosPosibles = Array.from(
    {length: 3}, // Cambia el número si deseas más años hacia el futuro
    (_, index) => añoActual - 1 + index
  );

  // Si el año actual no está en la lista, agrégalo
  if (!añosPosibles.includes(añoActual)) {
    añosPosibles.push(añoActual);
  }

  const columns = [
    {
      name: "Editar",
      width: "70px",
      cell: (row) => (
        <Button
          as={Link}
          to={`/editWereHouse/${row?.werehouseId}`}
          variant="primary"
        >
          <FontAwesomeIcon icon={faPen}/>
        </Button>
      ),
    },
    {
      name: "Visualizar",
      width: "90px",
      cell: (row) => (
        <Button
          as={Link}
          to={`/viewWereHouse/${row?.werehouseId}`}
          variant="success"
        >
          <FontAwesomeIcon icon={faEye}/>
        </Button>
      ),
    },
    {
      name: "Fecha",
      selector: (row) => row?.fecha,
      width: "170px",
    },
    {
      name: "Codigo",
      selector: (row) => row?.codigo,
      width: "100px",
    },
    {
      name: "Lote",
      selector: (row) => row?.lote,
      width: "110px",
    },
    {
      name: "VIN",
      selector: (row) => row?.vin,
      width: "185px",
    },
    {
      name: "Vehiculo",
      selector: (row) => row?.vehiculoName,
      width: "230px",
    },
    {
      name: "Color",
      selector: (row) => row?.color,
      width: "80px",
    },
    {
      name: "# Llave",
      selector: (row) => row?.numLlave,
      width: "80px",
    },
    {
      name: "Shipper Info",
      selector: (row) => row?.subName,
      width: "180px",
    },
    {
      name: "Consignee Info",
      selector: (row) => row?.impName,
      width: "180px",
    },
    {
      name: "Origen",
      selector: (row) => row?.origen?.state,
      width: "80px",
    },
    {
      name: "Destino",
      selector: (row) => row?.aduName,
      width: "110px",
    },
  ];

  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

  const handlePerRowsChange = (newPerPage) => {
    setPageCount(newPerPage);
    setCurrentPage(1);

    showWerehouse(1, newPerPage, filterText).then();
  };

  const handlePageChange = newPage => {
    setCurrentPage(newPage);
    showWerehouse(newPage, pageCount, filterText).then();
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <Container>
        <Row>
          <Col xs={12} md={12} className={'my-3'}>
            <Form.Label className="input-buscar">
              Seleccione un warehouse para Editar o Visualizar{" "}
            </Form.Label>
            <InputSearch placeholder={'Buscar Werehouse por Codigo o Lote'}
                         doChange={(e) => setFilterText(e)} />
          </Col>
        </Row>
      </Container>
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <Container className="werehouses">
      <div className="d-flex align-items-center justify-content-between">
        <h5 className="mb-0">WAREHOUSE RECEIPT</h5>
        <Button onClick={() => navigate('/warehouse/autoparts')}>Crear Warehouse</Button>
      </div>
      <hr/>
      <Row>
      <Col xs={12} md={10} lg={3}>
          <Form.Label>Mes:</Form.Label>
          <Form.Select
            value={currentMonth}
            onChange={(e) => setCurrentMonth(Number(e.target.value))}
          >
            {mesesDelAño.map((mes) => (
              <option key={mes.value} value={mes.value}>
                {mes.label}
              </option>
            ))}
          </Form.Select>
        </Col>

        <Col xs={12} md={10} lg={3}>
          <Form.Label>Año:</Form.Label>
          <Form.Select
            value={currentYear}
            onChange={(e) => setCurrentYear(Number(e.target.value))}
          >
            {añosPosibles.map((año) => (
              <option key={año} value={año}>
                {año}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <DataTable
            columns={columns}
            data={werehouses}
            paginationResetDefaultPage={resetPaginationToggle}
            paginationRowsPerPageOptions={[10, 25, 50, 100, 150, 200]}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            progressPending={isLoading}
            progressComponent={<SpinnerTable/>}
            persistTableHead
            pagination
            paginationServer
            paginationTotalRows={totalElements}
            paginationDefaultPage={currentPage}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            fixedHeader
            fixedHeaderScrollHeight="600px"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default TableWerehouse;
