import React, {useEffect, useState} from 'react';
import 'styled-components';
import '../hojas-de-estilo/style.css';
import {showSubastas, viewSub} from '../Servicios/ComponedoresService';
import {faLeftLong, faPenToSquare, faFileCirclePlus} from '@fortawesome/free-solid-svg-icons';
import {Link} from "react-router-dom";
import Button from 'react-bootstrap/Button';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Container, Row} from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SubastaForm from "../Componentes/SubastaForm/SubastaForm";
import {Modal, Table} from "antd";
import InputSearch from "../Componentes/InputSearch/InputSearch";
import Spinner from "../shared/spinner";

const Subastas = () => {
  const [showA, setShowA] = useState(false);
  const [showE, setShowE] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const handleCloseA = () => setShowA(false);

  const handleShowA = () => setShowA(true);

  const handleCloseE = () => {
    setShowE(false);
    setSelectedSubasta(undefined);
  };

  const handleShowE = () => setShowE(true);

  const [subast, setSubast] = useState([]);
  const [filteredSubastas, setFilteredSubastas] = useState([]);

  const mostrarSubs = async () => {
    setIsLoading(true);
    const data = await showSubastas();
    setIsLoading(false);

    setSubast(data);
    setFilteredSubastas(data);
  }

  useEffect(() => {
    mostrarSubs().then();
  }, [])

  const mostSubs = (id) => {
    if (id !== undefined && id !== 0)
      setIsLoading(true);
      viewSub(id).then((subastas) => {
        setIsLoading(false);
        setSelectedSubasta(subastas)
        handleShowE();
      });
  }

  const [selectedSubasta, setSelectedSubasta] = useState(undefined);

  const insertarSubasta = async () => {
    handleCloseA();
    mostrarSubs().then();
  }

  const editarSubasta = async () => {
    handleCloseE();
    mostrarSubs().then();
  }

  const columns = [
    {
      width: "80px",
      render: (_, element) => {
        return <Button value={element.id} size={'sm'} onClick={() => mostSubs(element?.subId)} variant="primary">
          <FontAwesomeIcon icon={faPenToSquare}/>
        </Button>
      },
    },
    {
      key: 'subName',
      dataIndex: 'subName',
      title: 'Subasta',
      width: "300px"
    },
    {
      title: 'Dirección',
      key: 'direccion',
      dataIndex: 'direccion',
      width: "510px"
    },
    {
      title: 'Telefono',
      key: 'phoneNumber',
      dataIndex: 'phoneNumber',
      width: "160px"
    },
    {
      title: 'Tipo de Identificación',
      key: 'identfType',
      dataIndex: 'identfType',
      width: "340px"
    },
    {
      title: 'Identificación',
      key: 'identf',
      dataIndex: 'identf',
      width: "210px"
    },
  ];

  return (
    <Spinner loading={isLoading}>
      <Container fluid={true}>
        <Row>
          <Col>
            <Button as={Link} style={{width: "150px"}} to='/home' variant="warning">
              <FontAwesomeIcon icon={faLeftLong}/>
              &nbsp;Regresar
            </Button>
            {' '}&nbsp;&nbsp;&nbsp;
            <Button onClick={handleShowA} variant="primary">
              <FontAwesomeIcon icon={faFileCirclePlus}/>
              &nbsp;Agregar Subasta
            </Button>
            {' '}&nbsp;
            <hr/>

            <h2>Visualizando Subastas</h2>
            <Row>
              <Col xs={12}>
                <br/>
                <InputSearch placeholder={'Buscar por nombre de subasta'}
                             doChange={value => {
                               setFilteredSubastas(subast.filter(
                                 item => !value || (item.subName && item.subName.toString().toLowerCase().includes(value.toLowerCase()))
                               ));
                             }}/>
                <br/>
                <Table size={"small"}
                       columns={columns}
                       dataSource={filteredSubastas}
                       pagination={{pageSize: 10}}/>
              </Col>
              <ToastContainer/>
            </Row>
          </Col>
        </Row>
      </Container>

      <Modal
        open={showA}
        closable={false}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        title={'Add new auction'}
      >
        <SubastaForm onCancel={handleCloseA} afterSave={insertarSubasta} />
      </Modal>

      <Modal
        open={showE}
        closable={false}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        title={'Editar Subasta'}
      >
        <SubastaForm subasta={selectedSubasta} onCancel={handleCloseE} afterSave={editarSubasta} />
      </Modal>
    </Spinner>
  )
}

export default Subastas;
