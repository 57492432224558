import Spinner from "../../shared/spinner";
import {Form, InputNumber, Select} from "antd";
import {useEffect, useState} from "react";
import {getExtraItems} from "../../Servicios/ExtraItemService";

const ExtraItemReactiveForm = ({ extraItem, reloadExtraItems, showRequired, clearFields, getExtraItem }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [extraItems, setExtraItems] = useState([]);

  useEffect(() => {
    if (extraItem) {
      form.setFieldsValue({
        extraItemName: extraItem.extraItemName,
        quantity: extraItem.quantity,
        weight: extraItem.weight,
      });
    }
  }, [extraItem]);

  useEffect(() => {
    if (form && showRequired) {
      form.checkForField();
    }
  }, [showRequired]);

  useEffect(() => {
    if (form && clearFields) {
      form.resetFields();
    }
  }, [clearFields]);

  useEffect(() => {
    if (reloadExtraItems) {
      loadExtraItems().then();
    }
  }, [reloadExtraItems]);

  useEffect(() => {
    loadExtraItems().then();
  }, []);

  const loadExtraItems = async () => {
    setLoading(true);
    const data = await getExtraItems();
    setLoading(false);
    setExtraItems(data);
  }

  const handleSearch = value => {
    const data = extraItems.find(e => e.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);

    if (!data) {
      const index = extraItems.findIndex(e => e.id === '-1');
      if (index === -1) {
        setExtraItems([...extraItems, { name: value, id: '-1' }]);
      } else {
        const items = [...extraItems];
        items[index].name = value;

        setExtraItems([...items]);
      }
    }
  }

  const handleExtraItemNameChange = value => {
    getExtraItem({
      extraItemName: value,
      quantity: form.getFieldValue("quantity"),
      weight: form.getFieldValue('weight'),
    });
  }

  const handleQuantityChange = value => {
    getExtraItem({
      extraItemName: form.getFieldValue("extraItemName"),
      quantity: value,
      weight: form.getFieldValue('weight'),
    });
  }

  const handleWeightChange = value => {
    getExtraItem({
      extraItemName: form.getFieldValue("extraItemName"),
      quantity: form.getFieldValue("quantity"),
      weight: value,
    });
  }

  const handleBlur = () => {
    setExtraItems(extraItems.filter(e => e.id !== '-1'));
  }

  return(
    <Spinner loading={loading}>
      <Form form={form} layout={'vertical'}>
        <Form.Item label={'Nombre del Item'} name={'extraItemName'}>
          <Select showSearch={true}
                  onBlur={handleBlur}
                  onSearch={handleSearch}
                  onChange={handleExtraItemNameChange}
                  options={extraItems.map((item) => ({label: item.name, value: item.name}))} />
        </Form.Item>

        <Form.Item label={'Peso (Kg)'} name={'weight'}>
          <InputNumber className="w-100" onChange={handleWeightChange} />
        </Form.Item>

        <Form.Item label={'Cantidad'} name={'quantity'}>
          <InputNumber className="w-100" onChange={handleQuantityChange} />
        </Form.Item>
      </Form>
    </Spinner>
  );
}

export default ExtraItemReactiveForm;
