import React, {useState, useEffect} from 'react';
import {faArrowsRotate, faLeftLong, faEye, faPencil} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Button from 'react-bootstrap/Button';
import {Link, useNavigate, useParams} from "react-router-dom";
import {ToastContainer, toast} from 'react-toastify';
import '../hojas-de-estilo/style.css';
import Form from 'react-bootstrap/Form';
import {showSubasta, showAduana, showOrigen} from '../Servicios/CombosService';
import {viewQuote, editQuote, enviarEmail, cancelQuote} from '../Servicios/SolicitudesService';
import Select from 'react-select';
import {listClients} from '../Servicios/ClienteService';
import {Container, Col, Row} from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import moment from 'moment';
import car from '../image/card.png';
import {permisos} from '../utils/permisos';
import {getCountriesId, hasRol, isSeller, validarPermisos} from '../Servicios/AuthService';
import LoadingInside from '../Componentes/LoadingInside';
import {SolicitudEstadoEnum} from "../shared/enums/solicitud-estado.enum";
import {SolicitudEnRevisionCorreccionEmail} from "../Resources/HtmlEmailBody/SolicitudEnRevisionCorreccion.email";
import environment from "../environments/environment";
import {getUserSellers} from "../Servicios/UsuarioService";
import VehiculoForm from "../Componentes/VehiculoForm/VehiculoForm";
import {Modal} from "antd/lib"
import {ClienteTypeEnum} from "../shared/enums/cliente-type.enum";
import ClienteForm from "../Carteracliente/ClienteForm";
import InputSearchCustomer from "../Componentes/InputSearchCustomer/InputSearchCustomer";
import useCurrentUser from "../Hooks/UserHook";
import Spinner from "../shared/spinner";
import {NotificarSolicitudDatosCompletados} from "../Resources/HtmlEmailBody/NotificarSolicitudDatosCompletados";
import CancelarSolicitudBtn from "./CancelarSolicitudBtn";
import {SolicitudCambioAduana} from '../Resources/HtmlEmailBody/SolicitudCambioAduana';
import {errorMessage, successMessage} from "../utils/toast-message";
import ShowWhen from "../Componentes/ShowWhen/ShowWhen";
import roles from "../utils/roles";

const EditarQuote = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const currentUser = useCurrentUser();

  const [subastas, setSubastas] = useState([]);
  const [aduanas, setAduanas] = useState([]);
  const [origen, setOrigen] = useState([]);

  const [subasta, setSubasta] = useState(undefined);
  const [cliente, setCliente] = useState(undefined);
  const [vehiculo, setVehiculo] = useState(undefined);
  const [clienteFinal, setClienteFinal] = useState(undefined);
  const [selectedImportadora, setSelectedImportadora] = useState(undefined);

  const [showModalClient, setShowModalClient] = useState(false);
  const [showModalVehicle, setShowModalVehicle] = useState(false);
  const [showModalFinalClient, setShowModalFinalClient] = useState(false);
  const [estadoPendienteCompletar, setEstadoPendienteCompletar] = useState(false);

  const handleCloseModalVehicle = () => setShowModalVehicle(false);

  const handleShowModalVehicle = () => setShowModalVehicle(true);

  const [newQuote, setNewQuote] = useState(undefined);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [userList, setUserList] = useState([]);
  const [aduanaAnterior, setAduanaAnterior] = useState(null);

  const dat = moment(newQuote?.fechaQ).format('ll');

  const puedeAgregarSolicitud = validarPermisos(permisos.AGREGAR_SOLICITUDES);

  const infoEdit = () => {
    setIsLoading(true);
    Promise
      .all([
        getUserSellers(),
        viewQuote(id)
      ])
      .then(([userSellers, quo]) => {
        setIsLoading(false);

        setNewQuote({
          ...quo,
          aduanaId: quo.aduana && quo.aduana.isProvisional ? '0' : quo.aduanaId,
        });
        setAduanaAnterior(quo.aduanaId);
        setSubasta(quo.subasta);
        setCliente(quo.clientes);
        setUserList(userSellers);
        setVehiculo(quo.vehiculo);
        setClienteFinal(quo.clienteFinal);
        setSelectedImportadora(quo.clientes.importadora);
        setEstadoPendienteCompletar(quo.estado === SolicitudEstadoEnum.PendienteCompletar && isSeller());

        let userId = quo.userSellerId;
        if (userId === 0) {
          const user = userSellers.find(e => `${e.firstName} ${e.lastName}`.toLowerCase() === quo.creador.toLowerCase());
          if (user) {
            userId = user.idUser;
          }
        }

        setSelectedUser(userId);
      }, () => {
        setIsLoading(false);
      });
  }

  const handleVehicleUpdated = vehicle => {
    notifyCar();
    handleCloseModalVehicle();
    setVehiculo(vehicle);
  }


  useEffect(() => {
    loadClientes().then();
    loadLists().then();

    infoEdit();
  }, [id]);

  const notify = () => toast.success("Se actualizo informacion correctamente, Notifique los cambios por correo", {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const notifyCar = () => toast.success("Se actualizo informacion de vehiculo", {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const loadClientes = async () => {
    await listClients();
    await listClients("0", 0, 0, 0, "", ClienteTypeEnum.typeFinalCustomer);
  }

  const loadLists = async () => {
    const countriesId = getCountriesId();

    const subastas = await showSubasta();
    setSubastas(subastas);

    const aduanas = await showAduana();
    setAduanas(aduanas.filter(e => !e.isProvisional).filter(e => countriesId === "0" || countriesId.indexOf(e.countryId) !== -1));

    const origen = await showOrigen();
    setOrigen(origen);
  }

  const handleSelectChangeCliente = customer => {
    setCliente(customer);
    setSelectedImportadora(customer.importadora);

    setNewQuote({
      ...newQuote,
      clienteId: customer.id,
      importadoraId: customer.importadoraId
    });
  }

  const handleSelectChangeClienteFinal = customer => {
    setClienteFinal(customer);
    setNewQuote({
      ...newQuote,
      idClienteFinal: customer.id,
    });
  }

  const handleSelectChangeSubs = ({value}) => {
    const subasta = subastas.find(e => e.subId === value);
    setSubasta(subasta);

    setNewQuote({
      ...newQuote,
      subastaId: value
    });
  }

  const handleSelectChangeAdu = e => {
    const {value} = e.target;
    const aduana = aduanas.find(e => +e.id === +value);

    setNewQuote({
      ...newQuote,
      aduanaId: value,
      countryId: aduana.countryId
    });
  }

  const handleChange = e => {
    const {name, value} = e.target;
    setNewQuote({
      ...newQuote,
      [name]: value
    });
    setVehiculo({
      ...vehiculo,
      [name]: value
    });
  }

  const handleOnChangeCK = (evt) => {
    const {name, checked} = evt.target
    setNewQuote({
      ...newQuote,
      [name]: checked
    });
  }

  const caredit = () => {
    handleShowModalVehicle();
  }

  const editarCoti = async () => {
    if (!newQuote.aduanaId || newQuote.aduanaId === '' || newQuote.aduanaId === '0') {
      errorMessage('La aduana es requerida');
      return;
    }

    // actualizar estado
    const newEstado = newQuote.estado === SolicitudEstadoEnum.EnRevision
      ? SolicitudEstadoEnum.SinAsignar
      : newQuote.estado;

    const notificarAsignado = newQuote.estado === SolicitudEstadoEnum.EnRevision;
    const notificarCompletado = newQuote.estado === SolicitudEstadoEnum.PendienteCompletar;

    const input = {
      ...newQuote,
      lote: +newQuote.lote,
      buyer: +newQuote.buyer,
      clienteId: +newQuote.clienteId,
      vehiculoId: +newQuote.vehiculoId,
      aduanaId: +newQuote.aduanaId,
      countryId: +newQuote.countryId,
      origenId: +newQuote.origenId,
      subastaId: +newQuote.subastaId,
      importadoraId: +newQuote.importadoraId,
      costoQ: newQuote.costoQ.toString(),
      bl: newQuote.bl.toString(),
      quoted: newQuote.quoted.toString(),
      estGrua: newQuote.estGrua.toString(),
      estado: newEstado,
      idClienteFinal: newQuote.idClienteFinal ? +newQuote.idClienteFinal : null
    };

    setIsLoading(true);
    const data = await editQuote(id, input);
    setIsLoading(false);

    if (data && data.status) {
      notify();

      if (notificarAsignado) {
        const emails = [];
        const emailsCC = [];

        if (!environment.production) {
          newQuote.emailUserAssigned = "manuelpc2495@gmail.com";
          newQuote.emailUserCreated = "manuelpc2495@gmail.com";
          newQuote.emailUserSeller = "manuelpc2495@gmail.com";
        }

        if (data.data.aduanaId !== aduanaAnterior) {
          enviarEmail({
            to: environment.production ? environment.emails.grua : ["juanflores9152@gmail.com"],
            cc: [currentUser.email],
            cco: [],
            subject: `***CAMBIO DE ADUANA*** VEHICULO LOTE # ${newQuote?.lote} - ${vehiculo?.year} ${vehiculo?.make} ${vehiculo?.model}`,
            body: SolicitudCambioAduana(data.data)
          }).then();
        }

        if (notificarCompletado) {
          const emails = [];
          const emailsCC = [];

          enviarEmail({
            to: emails,
            cc: emailsCC,
            cco: [],
            subject: `Corrección de Solicitud ${newQuote.lote} Cliente: ${cliente?.nombreCompleto}`,
            body: SolicitudEnRevisionCorreccionEmail(newQuote, cliente?.nombreCompleto)
          }).then();
        }

        if (notificarCompletado) {
          const emails = [];
          const emailsCC = [];

          if (!environment.production) {
            newQuote.emailUserAssigned = "manuelpc2495@gmail.com";
            newQuote.emailUserCreated = "manuelpc2495@gmail.com";
            newQuote.emailUserSeller = "manuelpc2495@gmail.com";
          }

          if (newQuote.emailUserAssigned && newQuote.emailUserAssigned !== "") emails.push(newQuote.emailUserAssigned);
          if (newQuote.emailUserCreated && newQuote.emailUserCreated !== "") emailsCC.push(newQuote.emailUserCreated);
          if (newQuote.emailUserSeller && newQuote.emailUserSeller !== "") emailsCC.push(newQuote.emailUserSeller);

          enviarEmail({
            to: emails,
            cc: emailsCC,
            cco: [],
            subject: `Datos completados de la Solicitud ${newQuote.lote}. Cliente: ${cliente?.nombreCompleto}`,
            body: NotificarSolicitudDatosCompletados(newQuote, cliente?.nombreCompleto)
          }).then();
        }

        navigate('/requests/list');
      } else {
        errorMessage(data.message);
      }
    } else {
      errorMessage(data.message);
    }
  }

  const handleCancelCustomer = () => {
    if (showModalClient) setShowModalClient(false);
    if (showModalFinalClient) setShowModalFinalClient(false);
  };

  const handleSaveCustomer = async (customer) => {
    await loadClientes();

    if (showModalClient) {
      setCliente(customer);
      setSelectedImportadora(customer.importadora);
      setShowModalClient(false);

      setNewQuote({
        ...newQuote,
        clienteId: customer.id,
        importadoraId: customer.importadoraId
      });
    }

    if (showModalFinalClient) {
      setClienteFinal(customer);
      setShowModalFinalClient(false);

      setNewQuote({
        ...newQuote,
        idClienteFinal: customer.id
      });
    }
  };

  if (!newQuote) {
    return (
      <LoadingInside/>
    );
  }

  const handleCancelarSolicitud = async (description) => {
    setIsLoading(true);
    const data = await cancelQuote(newQuote.quoteId, {comentarios: description})
    setIsLoading(false);

    if (data) {
      successMessage("La solicitud fue cancelada correctamente");
      navigate('/requests/list');
    }
  }


  return (
    <Container>
      <Row>
        <h4>Editando solicitud Lote # {newQuote?.lote} del Cliente {cliente?.nombreCompleto} </h4>
        <Col>
          {puedeAgregarSolicitud &&
            <Button as={Link} style={{width: "150px"}} to='/requests/list' variant="warning">
              <FontAwesomeIcon icon={faLeftLong}/>
              &nbsp;Regresar
            </Button>
          }
        </Col>
      </Row>
      <br/>
      <Row className="justify-content-md-center">
        <hr/>
        <Col xs={12} md={12} lg="9">
          <Spinner loading={isLoading}>
            <Card>
              <Card.Header as="h5">Datos Solicitud</Card.Header>
              <Card.Body>
                <strong><Form.Label className='text-aling-left'>Fecha de Ingreso: {dat} </Form.Label></strong>
                <Card className="text-center">
                  <Card.Header>
                    Vehiculo Info. {!estadoPendienteCompletar && <Button onClick={() => caredit()}
                                                                         className="ms-2"
                                                                         style={{
                                                                           width: '75px',
                                                                           height: '25px',
                                                                           padding: '0px'
                                                                         }}
                                                                         variant="secondary">Editar <FontAwesomeIcon
                    icon={faEye}/></Button>}
                  </Card.Header>
                  <Card.Body>
                    <Card.Text>
                      <Row className="justify-content-md-center">
                        <Col xs lg="2">
                          <img // eslint-disable-line jsx-a11y/alt-text
                            src={car}
                            style={{width: "100px", height: "100px"}}/>
                        </Col>
                        <Col xs lg="4">
                          <Form.Label className='text-aling-left'>#Lote: {vehiculo?.lote}</Form.Label> <br/>
                          <Form.Label className='text-aling-left'>Vehiculo: {vehiculo?.vehiculoName}</Form.Label> <br/>
                          <Form.Label className='text-aling-left'>#VIN: {vehiculo?.vin}</Form.Label>
                        </Col>
                      </Row>
                    </Card.Text>

                  </Card.Body>
                </Card>
                <Card.Text>
                  <br/>
                  <Row>
                    <Col>
                      <div className="d-flex align-items-center justify-content-between mb-3">
                        <Form.Label className='text-aling-left m-0'>Cliente</Form.Label>
                        <ShowWhen show={!estadoPendienteCompletar}>
                          <Button variant={"primary"}
                                  size={"sm"}
                                  onClick={() => setShowModalClient(true)}>
                            <FontAwesomeIcon icon={faPencil}/> Agregar Cliente
                          </Button>
                        </ShowWhen>
                      </div>
                      <InputSearchCustomer disabled={estadoPendienteCompletar}
                                           selectedCustomer={cliente}
                                           filterByFinalCustomer={false}
                                           onSelectedCustomer={handleSelectChangeCliente}/>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      <Form.Label className='text-aling-left'>Subasta</Form.Label>
                      <Select
                        onChange={handleSelectChangeSubs}
                        defaultValue={{label: "Seleccionar otra Subasta/Ubicación aqui", value: "empty"}}
                        name='subastaId'
                        options={subastas && subastas.length && subastas.map(subs => ({
                          label: subs.subName + "-" + subs.direccion, value: subs.subId
                        }))}
                      />
                      <Form.Control readOnly
                                    disabled
                                    className="mt-3"
                                    aria-label="Small"
                                    value={subasta?.subName + "-" + subasta?.direccion}
                                    aria-describedby="inputGroup-sizing-sm"/>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col xs={12} lg="6">
                      <Form.Check
                        checked={newQuote?.dealer}
                        label="Matus Dealer?"
                        name="dealer"
                        onChange={handleOnChangeCK}
                        type="checkbox"
                      />
                    </Col>
                    <Col xs={12} lg="6">
                      <Form.Check
                        checked={newQuote?.cutVehicle}
                        label="Para corte de Vehículo?"
                        name="cutVehicle"
                        onChange={handleOnChangeCK}
                        type="checkbox"
                      />
                    </Col>
                    <Col xs={12} lg="6">
                      <Form.Label className='text-aling-left'>GatePass</Form.Label>
                      <Form.Control required aria-label="Small"
                                    onChange={handleChange}
                                    value={newQuote && newQuote?.gatePass}
                                    name='gatePass'
                                    disabled={estadoPendienteCompletar}
                                    aria-describedby="inputGroup-sizing-sm"/>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col xs={12} lg="6">
                      <Form.Label className='text-aling-left'>Origen</Form.Label>
                      <Form.Select onChange={handleChange}
                                   name='origenId'
                                   value={newQuote && newQuote?.origenId}
                                   aria-label="Small"
                                   disabled={estadoPendienteCompletar}
                                   aria-describedby="inputGroup-sizing-sm">
                        <option disabled selected>Seleccione</option>
                        {
                          origen && origen.length && origen.map((ori, index) => {
                            return <option key={index} value={ori.id}>{ori.nameOrigen}</option>
                          })
                        }
                      </Form.Select>
                    </Col>
                    <Col xs={12} lg="6">
                      <Form.Label className='text-aling-left'>Aduana destino</Form.Label>
                      <Form.Select onChange={handleSelectChangeAdu} name='aduanaId'
                                   value={newQuote && newQuote?.aduanaId} aria-label="Small"
                                   aria-describedby="inputGroup-sizing-sm">
                        <option value="0">Seleccione</option>
                        {
                          aduanas && aduanas.length && aduanas.map((adu, index) => {
                            return <option key={index} value={adu.id}>{adu.nameAduana} - {adu.countryName}</option>
                          })
                        }
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col xs={12} lg="6">
                      <Form.Label className='text-aling-left'>Buyer</Form.Label>
                      <Form.Control required aria-label="Small"
                                    onChange={handleChange}
                                    value={newQuote && newQuote?.buyer}
                                    disabled={estadoPendienteCompletar}
                                    name='buyer'
                                    aria-describedby="inputGroup-sizing-sm"/>
                    </Col>
                    <Col xs={12} lg="6">
                      <Form.Label className='text-aling-left'>Tipo de pago</Form.Label>
                      <Form.Control required aria-label="Small"
                                    onChange={handleChange}
                                    value={newQuote && newQuote?.tipoPago}
                                    disabled={estadoPendienteCompletar}
                                    name='tipoPago'
                                    aria-describedby="inputGroup-sizing-sm"/>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col xs={12} lg="3">
                      <Form.Label className='text-aling-left'>Costo de Flete</Form.Label>
                      <Form.Control required aria-label="Small"
                                    onChange={handleChange}
                                    value={newQuote && newQuote?.quoted}
                                    name='quoted'
                                    aria-describedby="inputGroup-sizing-sm"/>
                    </Col>
                    <Col xs={12} lg="3">
                      <Form.Label className='text-aling-left'>Costo de Grua</Form.Label>
                      <Form.Control aria-label="Small"
                                    onChange={handleChange}
                                    value={newQuote && newQuote?.estGrua}
                                    name='estGrua'
                                    disabled={estadoPendienteCompletar}
                                    aria-describedby="inputGroup-sizing-sm"/>
                    </Col>
                    <Col xs={12} lg="3">
                      <Form.Label className='text-aling-left'>Costo BL</Form.Label>
                      <Form.Control aria-label="Small"
                                    onChange={handleChange}
                                    value={newQuote && newQuote?.bl}
                                    name='bl'
                                    disabled={estadoPendienteCompletar}
                                    aria-describedby="inputGroup-sizing-sm"/>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col xs={12} lg="3">
                      <Form.Label className='text-aling-left'>Cargos Adicionales</Form.Label>
                      <Form.Control aria-label="Small"
                                    onChange={handleChange}
                                    value={newQuote && newQuote?.costoQ}
                                    name='costoQ'
                                    disabled={estadoPendienteCompletar}
                                    aria-describedby="inputGroup-sizing-sm"/>
                    </Col>
                    <Col xs={12} lg="9">
                      <Form.Label className='text-aling-left'>Descripción de Costos Adicionales</Form.Label>
                      <Form.Control aria-label="Small"
                                    onChange={handleChange}
                                    value={newQuote && newQuote?.descripcionCostoAdicional}
                                    name="descripcionCostoAdicional"
                                    disabled={estadoPendienteCompletar}
                                    aria-describedby="inputGroup-sizing-sm"/>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      <Form.Label className='text-aling-left'>Comentarios</Form.Label>
                      <Form.Control maxLength={150} onChange={handleChange} value={newQuote && newQuote?.comentarios}
                                    name='comentarios' as="textarea" rows={3}/>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      <div className="d-flex align-items-center justify-content-between mb-3">
                        <Form.Label className='text-aling-left m-0'>Cliente Final (Opcional)</Form.Label>
                        <Button variant={"primary"}
                                size={"sm"}
                                onClick={() => setShowModalFinalClient(true)}>
                          <FontAwesomeIcon icon={faPencil}/> Agregar Cliente
                        </Button>
                      </div>
                      <InputSearchCustomer selectedCustomer={clienteFinal}
                                           filterByFinalCustomer={true}
                                           onSelectedCustomer={handleSelectChangeClienteFinal}/>
                    </Col>
                  </Row>
                  {
                    newQuote.estado === SolicitudEstadoEnum.EnRevision ?
                      <Row className="mt-3">
                        <Col>
                          <Form.Label className='text-aling-left'>Notas de Revisión</Form.Label>
                          <Form.Control maxLength={150}
                                        onChange={handleChange}
                                        value={newQuote?.proceso ?? ""}
                                        name='proceso'
                                        as="textarea"
                                        rows={3}/>
                        </Col>
                      </Row> : <></>
                  }
                  <Row className="mt-3">
                    <Col>
                      <Form.Label className="text-aling-left" style={{color: "gray"}}>
                        Registrado por: <br/> {newQuote?.registradoBy}
                      </Form.Label>
                    </Col>
                    <Col>
                      <Form.Label className="text-align-right">Asignado a:</Form.Label>
                      <Form.Select
                        value={selectedUser}
                        onChange={e => {
                          const user = userList.find(user => user.idUser === +e.target.value);

                          setSelectedUser(user.idUser);
                          setNewQuote(prevState => ({
                            ...prevState,
                            creador: `${user.firstName} ${user.lastName}`,
                            userSellerId: user.idUser
                          }));
                        }}
                      >
                        <option value={currentUser?.fullName}>
                          {currentUser?.fullName}
                        </option>
                        {userList.map(user => (
                          <option key={user.idUser} value={user.idUser}>
                            {user.firstName} {user.lastName}
                          </option>
                        ))}
                      </Form.Select>
                      <br/>
                    </Col>
                  </Row>

                </Card.Text>
                <Row className="justify-content-md-center">
                  <Col xs="12" lg="8" className="d-flex gap-3">
                    <Button onClick={() => editarCoti()} variant="primary">
                      <FontAwesomeIcon icon={faArrowsRotate}/>
                      &nbsp;Guardar Cambios
                    </Button>
                    {
                      puedeAgregarSolicitud && hasRol(roles.ADMIN, roles.SUPERVISOR) &&
                      <CancelarSolicitudBtn onCancelar={handleCancelarSolicitud}/>
                    }
                    <ToastContainer/>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Spinner>
        </Col>
      </Row>

      <Modal open={showModalVehicle}
             title={`Modificar Vehículo ${vehiculo?.make} ${vehiculo?.model} año ${vehiculo?.year}`}
             cancelButtonProps={{style: {display: "none"}}}
             okButtonProps={{style: {display: "none"}}}
             onCancel={() => handleCloseModalVehicle()}>
        <VehiculoForm vehicle={vehiculo}
                      onVehicleSaved={handleVehicleUpdated}/>
      </Modal>

      <Modal open={showModalClient || showModalFinalClient}
             closable={false}
             cancelButtonProps={{style: {display: "none"}}}
             okButtonProps={{style: {display: "none"}}}>
        <ClienteForm shortSaved={true}
                     countryId={0}
                     finalClient={showModalFinalClient}
                     submitCliente={handleSaveCustomer}
                     onCancel={handleCancelCustomer}/>
      </Modal>
    </Container>
  );
}

export default EditarQuote;
