import React, {useEffect, useState} from 'react';
import DataTable from 'react-data-table-component';
import 'styled-components';
import '../hojas-de-estilo/style.css';
import {showImports, viewImport} from '../Servicios/ComponedoresService';
import {faLeftLong, faPenToSquare, faFileCirclePlus} from '@fortawesome/free-solid-svg-icons';
import {Link} from "react-router-dom";
import Button from 'react-bootstrap/Button';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Form from 'react-bootstrap/Form';
import {Container, Row} from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import SpinnerTable from '../Componentes/SpinnerTable';
import ImportadoraForm from "../Componentes/ImportadoraForm/ImportadoraForm";
import {Modal} from "antd";

const FilterComponent = ({filterText, onFilter}) => (
  <>
    <Form.Control
      className="input-buscar"
      id="search"
      type="text"
      placeholder="Buscar Importadora"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
  </>
);

const Importadoras = () => {

  const [showA, setShowA] = useState(false);
  const [showE, setShowE] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const handleCloseA = () => setShowA(false);
  const handleShowA = () => setShowA(true);

  const handleCloseE = () => {
    setShowE(false);
    setImportadora(undefined);
  };

  const handleShowE = () => setShowE(true);

  const [importadora, setImportadora] = useState(undefined);
  const [importadoras, setImportadoras] = useState([]);

  const mostrarImports = async () => {
    setIsLoading(true);
    const data = await showImports().finally(() => {
      setIsLoading(false)
    });
    setImportadoras(data);
  }

  useEffect(() => {
    mostrarImports().then();
  }, [])

  const mostImp = (id) => {
    if (id !== undefined && id !== 0)
      viewImport(id).then((importadora) => {
        setImportadora(importadora)
        handleShowE();
      });
  }

  const insertarImport = async () => {
    handleCloseA();
    mostrarImports().then();
  }

  const editarImport = async () => {
    handleCloseE();
    mostrarImports().then();
  }

  const columns = [
    {
      name: 'Editar',
      width: "80px",
      cell: (row) => <Button value={row.id} onClick={() => mostImp(row?.id)} variant="primary">
        <FontAwesomeIcon icon={faPenToSquare}/>
      </Button>
    },
    {
      name: 'Importadora',
      selector: row => row.impName,
      width: "250px"
    },
    {
      name: 'Dirección',
      selector: row => row.direccion,
      width: "700px"
    },
  ]

  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const filteredItems = importadoras.filter(
    item => item?.impName && item?.impName.toString().toLowerCase().includes(filterText.toLowerCase()),
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <Container>
        <Row>
          <Col xs={12} md={5}>
            <Form.Label className='input-buscar'>Seleccione Importadora para Editar </Form.Label>
            <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear}
                             filterText={filterText}/>
          </Col>
        </Row>
        <br/>
      </Container>
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <Container className='imports'>
      <Row>
        <Col>
          <Button as={Link} style={{width: "150px"}} to='/home' variant="warning">
            <FontAwesomeIcon icon={faLeftLong}/>
            &nbsp;Regresar
          </Button>
          {' '}&nbsp;&nbsp;&nbsp;
          <Button onClick={handleShowA} variant="primary">
            <FontAwesomeIcon icon={faFileCirclePlus}/>
            &nbsp;Agregar Importadora
          </Button>
          {' '}&nbsp;
          <hr/>

          <h2>Visualizando Importadoras</h2>
          <Row>
            <Col xs={12}>
              <br/>
              <DataTable
                columns={columns}
                data={filteredItems}
                paginationResetDefaultPage={resetPaginationToggle}
                paginationRowsPerPageOptions={[25, 50, 100, 150, 200]}
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                progressPending={isLoading}
                progressComponent={<SpinnerTable/>}
                persistTableHead
                pagination
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal open={showA}
             title={'Agregar Nueva Importadora'}
             onCancel={handleCloseA}
             okButtonProps={{style: {display: "none"}}}
             cancelButtonProps={{style: {display: "none"}}}>
        <ImportadoraForm onCancel={handleCloseA} afterSubmit={insertarImport} />
      </Modal>

      <Modal open={showE}
             title={'Modificar Importadora'}
             onCancel={handleCloseE}
             okButtonProps={{style: {display: "none"}}}
             cancelButtonProps={{style: {display: "none"}}}>
        <ImportadoraForm importadora={importadora} onCancel={handleCloseE} afterSubmit={editarImport} />
      </Modal>
    </Container>
  )
}

export default Importadoras;
