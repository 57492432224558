import {useEffect, useState} from "react";
import {getExportations} from "../../../Servicios/ExportationServices";
import {ExportationStateEnum} from "../../../shared/enums/exportation-state.enum";
import InputSearch from "../../../Componentes/InputSearch/InputSearch";
import Spinner from "../../../shared/spinner";
import ExportationTable from "../ExportationTable";
import useCurrentUser from "../../../Hooks/UserHook";
import {useNavigate} from "react-router-dom";

const ExportationCompletadosPage = () => {
  const navigate = useNavigate();
  const currentUser = useCurrentUser();

  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);

  const [exportations, setExportations] = useState([]);
  const [pagination , setPagination] = useState({current: 1, pageSize: 5, total: 0 });

  useEffect(() => {
    if (currentUser) {
      initExportations(pagination.current, pagination.pageSize, search).then();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const initExportations = async (currentPage, pageCount, search = "") => {
    const origen = +currentUser.idubc;

    setLoading(true);
    const data = await getExportations(origen, currentPage, pageCount, search, ExportationStateEnum.Completado);
    setLoading(false);

    if (data && data.status) {
      setExportations(data.list.map((e, i) => ({...e, key: i})));

      if (pagination.total === 0) {
        setPagination({ ...pagination, total: data.totalElements });
      }
    }
  }

  const handlePageChange = (pagination) => {
    setPagination({
      ...pagination,
      current: pagination.current,
    });

    initExportations(pagination.current, pagination.pageSize, search).then();
  }

  const handleSearch = (value) => {
    setSearch(value);
    setPagination({...pagination, current: 1})
    initExportations(1, pagination.pageSize, value).then();
  }

  return(
    <>
      <Spinner loading={loading}>
        <div className="mb-3">
          <h5>Exportaciones Completadas</h5>
        </div>
        <div className="mb-3">
          <InputSearch placeholder={'Search by shipping name or booking number'}
                       doChange={handleSearch} />
        </div>
        <ExportationTable pagination={pagination}
                          exportations={exportations}
                          onPageChange={handlePageChange}
                          onRefreshTable={() => initExportations(pagination.current, pagination.pageSize, search)}
                          onEditExportation={(exportation) => navigate(`/exportation/edit/${exportation.exportationId}`)} />
      </Spinner>
    </>
  );
}

export default ExportationCompletadosPage;
