import Spinner from "../../../shared/spinner";
import {Button, DatePicker, Form, Input, InputNumber, Select, Table} from "antd";
import {useForm} from "antd/es/form/Form";
import {useEffect, useState} from "react";
import {getAduana, getColor, getContainer, getListCarrier, getWareHouse} from "../../../Servicios/ExportationServices";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave, faTimes, faTrash, faWarning} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import {
  addExportationContainer,
  updateExportationContainer
} from "../../../Servicios/ExportationContainerService";
import {errorMessage, successMessage} from "../../../utils/toast-message";
import dayjs from "dayjs";
import {DetalleExportacionStateEnum} from "../../../shared/enums/detalle-exportacion-state.enum";
import TextArea from "antd/lib/input/TextArea";
import {exportationContainerStateEnum} from "../../../shared/enums/exportation-container-state.enum";
import ShowWhen from "../../../Componentes/ShowWhen/ShowWhen";

const ExportationContainerForm = ({ idOrigen, exportationContainer, editable, onCancel, onSubmit }) => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);

  const [selectedWarehouses, setSelectedWarehouses] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [containers, setContainers] = useState([]);
  const [carriers, setCarriers] = useState([]);
  const [aduanas, setAduanas] = useState([]);
  const [colors, setColors] = useState([]);

  useEffect(() => {
    setLoading(true);
    init().then(() => {
      setLoading(false);
    });
  }, [exportationContainer]);

  const init = async () => {
    setLoading(true);

    if (aduanas.length === 0) {
      const aduanaList = await getAduana();
      setAduanas(aduanaList.filter(e => !e.isProvisional));
    }

    if (containers.length === 0) {
      const containerList = await getContainer();
      setContainers(containerList);
    }

    if (carriers.length === 0) {
      const carrierList = await getListCarrier();
      setCarriers(carrierList);
    }

    if (colors.length === 0) {
      const colorList = await getColor();
      setColors(colorList);
    }

    form.setFieldsValue({
      sequence: exportationContainer ? exportationContainer.sequence : '',
      idContainer: exportationContainer ? exportationContainer.idContainer : '',
      idCarrier: exportationContainer ? exportationContainer.idCarrier : '',
      idAduana: exportationContainer ? exportationContainer.idAduana : '',
      idColor: exportationContainer ? exportationContainer.idColor : '',
      loadingDate: exportationContainer ? dayjs(exportationContainer.loadingDate) : dayjs(new Date()),
      letter: exportationContainer ? exportationContainer.letter : '',
      sealNumber: exportationContainer ? exportationContainer.sealNumber : '',
      containerNumber: exportationContainer ? exportationContainer.containerNumber : '',
      captain: exportationContainer ? exportationContainer.captain : '',
      loadedNotes: exportationContainer ? exportationContainer.loadedNotes : '**NO QUITAR LLANTAS**',
    });

    if (exportationContainer) {
      setSelectedWarehouses(exportationContainer.detalleExport.map(x => x.werehouse));
    }
  }

  const findWarehousesByAduana = async (aduanaId) => {
    setLoading(true);
    const warehouses = await getWareHouse(aduanaId, idOrigen, true);
    setLoading(false);

    setWarehouses(warehouses);
  }

  const handleSubmit = async (values) => {
    try {
      const input = {
        ...values,
        idOrigen: idOrigen,
        loadingDate: moment(values.loadingDate.$d).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
        werehouses: selectedWarehouses.map(e => e.werehouseId)
      }

      setLoading(true);
      const data = exportationContainer
        ? await updateExportationContainer(exportationContainer.idExportationContainer, input)
        : await addExportationContainer(input);
      setLoading(false);

      if (data) {
        successMessage('Contenedor asignado con exito');
        form.setFieldValue('sequence', '');
        form.setFieldValue('idColor', '');
        form.setFieldValue('letter', '');
        form.setFieldValue('sealNumber', '');
        form.setFieldValue('containerNumber', '');
        form.setFieldValue('captain', '');
        form.setFieldValue('loadedNotes', '**NO QUITAR LLANTAS**');
        form.setFieldValue('createdDate', dayjs(new Date()));
        setSelectedWarehouses([]);
        if (onSubmit) onSubmit(data);
      }
    } catch (ex) {
      setLoading(false);
      errorMessage(`${ex.toString()}`);
    }
  }

  const handleCancel = () => {
    form.setFieldValue('sequence', '');
    form.setFieldValue('idColor', '');
    form.setFieldValue('letter', '');
    form.setFieldValue('sealNumber', '');
    form.setFieldValue('containerNumber', '');
    form.setFieldValue('captain', '');
    form.setFieldValue('createdDate', dayjs(new Date()));
    form.setFieldValue('loadingDate', dayjs(new Date()));
    form.setFieldValue('loadedNotes', '**NO QUITAR LLANTAS**');
    setSelectedWarehouses([]);

    if (onCancel) onCancel();
  }

  const warehouseTemplate = warehouse => {
    if (warehouse.hasVehicle)
      return(
        <>
          <div className="d-flex align-items-center justify-content-between">
            <p className="mb-0 fw-bold">{warehouse.codigo}</p>
            <p className="mb-0 fw-bold"># LOTE: {warehouse.lote}</p>
          </div>
          <p className="mb-0">{warehouse.vehiculoName}</p>
          <p className="mb-0">VIN: {warehouse.vin}</p>
        </>
      );

    return(
      <>
        <div className="d-flex align-items-center justify-content-between">
          <p className="mb-0 fw-bold">{warehouse.codigo}</p>
          <p className="mb-0 fw-bold"><FontAwesomeIcon icon={faWarning} className="me-2"/> Warehouse con AutoPartes</p>
        </div>
        <p className="mb-0">{warehouse?.extraItems?.[0]?.extraItemName}</p>
        <p className="mb-0">Cant.: {warehouse?.extraItems?.[0]?.quantity} | Peso.: {warehouse?.extraItems?.[0]?.weight} Kg</p>
      </>
    );
  }

  const handleSelectWerehouse = werehouseId => {
    const werehouse = warehouses.find(e => e.werehouseId === werehouseId);

    if (!selectedWarehouses.some(e => e.werehouseId === werehouse.werehouseId)) {
      setSelectedWarehouses([...selectedWarehouses, werehouse]);
    }
  }

  const handleDeleteWerehouse = warehouse => {
    if (exportationContainer) {
      const existingDetalleExport = exportationContainer.detalleExport.find(e => e.idWR === warehouse.werehouseId);
      if (existingDetalleExport && existingDetalleExport.stateSend !== DetalleExportacionStateEnum.NoEnviado) {
        errorMessage('No se puede eliminar porque ya ha generado un # ITN');
        return;
      }
    }

    const werehouses = selectedWarehouses.filter(e => e.werehouseId !== warehouse.werehouseId);
    setSelectedWarehouses([...werehouses]);
  }

  const handleSelectAduana = aduanaId => {
    setSelectedWarehouses([]);
    findWarehousesByAduana(aduanaId).then();
  }

  const columns = [
    {
      width: 80,
      render: (_, element) => <ShowWhen show={!exportationContainer || exportationContainer.state !== exportationContainerStateEnum.cargado}>
        <Button type={'default'} danger={true} className="ms-3" onClick={() => handleDeleteWerehouse(element)}>
          <FontAwesomeIcon size={'sm'} icon={faTrash}/>
        </Button>
      </ShowWhen>
    },
    { width: 100, key: 'codigo', dataIndex: 'codigo', title: 'Código' },
    { width: 100, key: 'lote', dataIndex: 'lote', title: 'Lote' },
    {
      width: 250, key: 'vehiculo', dataIndex: 'vehiculo', title: 'Vehículo',
      render: (_, element) => {
        if (element.hasVehicle) {
          return <div>
            <p className="mb-0"><span className="fw-bold">VIN:</span> {element.vin}</p>
            <p className="mb-0">{element.vehiculoName}</p>
          </div>;
        } else {
          return <div>
            <p className="mb-0">{element?.extraItems?.[0]?.extraItemName}</p>
            <p className="mb-0">(x{element?.extraItems?.[0]?.quantity})</p>
          </div>;
        }
      }
    },
    {
      width: 250, key: 'aduana', dataIndex: 'aduana', title: 'Aduana',
      render: (_, element) => <div>
        <p className="mb-0 fw-bold">Aduana:</p>
        <p className="mb-0">{element.aduName} {element.aduana?.countryName}</p>
      </div>
    },
    {
      width: 150, key: 'flete', dataIndex: 'flete', title: 'Flete',
      render: (_, element) => <div>$ {element?.flete ?? 0}</div>
    },
    {
      width: 250, key: 'importadora', dataIndex: 'importadora', title: 'Importadora',
      render: (_, element) => <div>
        <p className="mb-0 fw-bold">Importadora:</p>
        <p className="mb-0">{element.impName}</p>
      </div>
    }
  ];

  return (
    <Spinner loading={loading}>
      <Form form={form}
            layout={'vertical'}
            onFinish={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12 col-12">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <Form.Item name="sequence"
                           label="Secuencia"
                           rules={[{required: true, message: "La secuencia es requerida"}]}>
                  <InputNumber disabled={!editable} className="w-100"/>
                </Form.Item>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <Form.Item name="loadingDate"
                           label="Fecha de Carga"
                           rules={[{required: true, message: "La fecha es requerido"}]}>
                  <DatePicker disabled={!editable} className="w-100"/>
                </Form.Item>
              </div>
            </div>

            <Form.Item label={'Seleccionar Aduana'}
                       rules={[{required: true, message: "La Aduana es requerido"}]}
                       name={'idAduana'}>
              <Select showSearch
                      disabled={!editable}
                      onChange={handleSelectAduana}
                      filterOption={(input, option) =>
                        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                      options={aduanas.map(e => ({
                        label: `${e.nameAduana} ${e.countryName}`, value: e.id,
                      }))}/>
            </Form.Item>

            <Form.Item label={'Contenedor'}
                       rules={[{required: true, message: "El contenedor es requerido"}]}
                       name={'idContainer'}>
              <Select disabled={!editable} options={containers.map(e => ({label: e.nameContainer, value: e.containerId}))}/>
            </Form.Item>

            <Form.Item label={'Nota de carga'}
                       name={'loadedNotes'}>
              <TextArea disabled={!editable} />
            </Form.Item>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 col-12">
            <Form.Item label={'Carrier'}
                       rules={[{required: true, message: "El carrier es requerido"}]}
                       name={'idCarrier'}>
              <Select showSearch
                      disabled={!editable}
                      filterOption={(input, option) => {
                        return (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                      }}
                      options={carriers.map(e => ({label: e.oceanCarrierName, value: e.carrierId}))}/>
            </Form.Item>

            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                <Form.Item label={'Color de Cono'}
                           rules={[{required: true, message: "El color de cono es requerido"}]}
                           name={'idColor'}>
                  <Select disabled={!editable} options={colors.map(e => ({
                    label: <div style={{
                      backgroundColor: `${e.codigo}`,
                      color: "white",
                      textAlign: "center",
                      borderRadius: 5
                    }}>{e.name}</div>,
                    value: e.colorId
                  }))}/>
                </Form.Item>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                <Form.Item name="letter"
                           label="Letra">
                  <Input className="w-100"/>
                </Form.Item>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <Form.Item name="containerNumber"
                           label="# de Contenedor">
                  <Input className="w-100"/>
                </Form.Item>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <Form.Item name="sealNumber"
                           label="# de Sello">
                  <Input className="w-100"/>
                </Form.Item>
              </div>
            </div>
            <Form.Item name="captain"
                       label="Capitán">
              <Input className="w-100"/>
            </Form.Item>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="d-flex align-items-center justify-content-between mb-2">
              <p className="mb-0">Warehouse</p>
            </div>
            <Select className="w-100 mb-3"
                    showSearch
                    disabled={exportationContainer && exportationContainer.state === exportationContainerStateEnum.cargado}
                    value={'Seleccionar un Warehouse'}
                    placeholder={'Seleccionar un Warehouse'}
                    onChange={handleSelectWerehouse}
                    filterOption={(input, option) =>
                      (option?.searchLabel ?? "").toLowerCase().includes(input.toLowerCase())}
                    options={warehouses.map(e => ({
                      label: warehouseTemplate(e),
                      value: e.werehouseId,
                      searchLabel: `${e.codigo}-${e.lote}-${e.vehiculoName}-${e.vin}`,
                    }))}/>

            <Table size={'small'}
                   columns={columns}
                   dataSource={selectedWarehouses} scroll={{ x: 1300 }}
                   summary={(pageData) => {
                     let total = 0;

                     pageData.forEach(({ flete }) => {
                       total += flete;
                     });

                     return(
                       <>
                         <Table.Summary.Row>
                           <Table.Summary.Cell index={0} colSpan={5}></Table.Summary.Cell>
                           <Table.Summary.Cell index={1}>
                             <div className="fw-bold">$ {total}</div>
                           </Table.Summary.Cell>
                         </Table.Summary.Row>
                       </>
                     )
                   }} />
          </div>
          <div className="col-12 text-end mt-3">
            <Button htmlType={'button'} type={'default'} className="me-2" onClick={handleCancel}>
              <FontAwesomeIcon icon={faTimes} className="me-2"/> Cancelar
            </Button>
            <Button htmlType={'submit'} type={'primary'}><FontAwesomeIcon icon={faSave}
                                                                          className="me-2"/> Guardar</Button>
          </div>
        </div>
      </Form>
    </Spinner>
  );
}

export default ExportationContainerForm;
