import React, { useState, useEffect } from "react";
import {
  addWR, getWarehouseByCoordination,
} from "../Servicios/WerehouseService";
import { useParams, useNavigate } from "react-router-dom";
import { viewCoordi } from "../Servicios/CoordService";
import useCurrentUser from "../Hooks/UserHook";
import {Row, Button, Spin} from "antd";
import {errorMessage, successMessage} from "../utils/toast-message";
import ShowWhen from "../Componentes/ShowWhen/ShowWhen";
import {VehiculoEstadoEnum} from "../shared/enums/vehiculo-estado.enum";
import WarehouseForm from "./WarehouseForm";
import {WarehouseProvider} from "../contexts/WarehouseContext";

const WerehouseCoor = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const currentUser = useCurrentUser();

  const [loading, setLoading] = useState(false);
  const [warehouse, setWarehouse] = useState(undefined);
  const [coordinacion, setCoordinacion] = useState(undefined);

  useEffect(() => {
    if (id && currentUser) {
      init().then();
    }
  }, [id, currentUser]);

  const init = async () => {
    setLoading(true);
    const dataWarehouse = await getWarehouseByCoordination(id);
    const coor = await viewCoordi(id);
    setLoading(false);

    if (dataWarehouse && dataWarehouse.status) {
      setWarehouse(dataWarehouse.data);
    }

    setCoordinacion(coor);
  }

  const insertarWR = async (values) => {
    if (!coordinacion.fechaRecived || coordinacion.vehiculo.estado !== VehiculoEstadoEnum.recibidoEnBodega) {
      errorMessage('No se puede crear el warehouse por que el vehículo no ha sido recibido en bodega');
      return
    }

    try {
      setLoading(true);
      await addWR({
        ...values,
        shipped: 'NO EMBARCADO',
        idCoordi: coordinacion.id,
        countryId: coordinacion.countryId,
        clienteId: coordinacion.clienteId,
        importadoraId: +values.importadoraId,
        subastaId: +values.subastaId,
        typeMerchId: +values.typeMerchId,
        originId: +values.originId,
        schedulebId: +values.schedulebId,
        licenceTypeId: +values.licenceTypeId,
        expoCodeId: +values.expoCodeId,
        stateId: +values.stateId,
        quantity: +values.quantity,
        flete: +values.flete,
        grua: +values.grua,
        buyer: +values.buyer,
        costoAdicional: values.costoAdicional && values.costoAdicional !== ""
          ? +values.costoAdicional
          : null,
        solicitudCostoAdicional: values.solicitudCostoAdicional && values.solicitudCostoAdicional !== ""
          ? +values.solicitudCostoAdicional
          : null
      });
      setLoading(false);

      successMessage("Warehouse regitrado correctamente");
      navigate(`/coordinations/country/${coordinacion.origenId}`);
    } catch (ex) {
      setLoading(false);
      errorMessage(ex.toString());
    }
  };

  return (
    <WarehouseProvider>
      <Spin spinning={loading}>
        <ShowWhen show={!!warehouse}>
          <Row gutter={16} justify={"center"}>
            <div className="col-12">
              <h4>
                Warehouse de {coordinacion?.vehiculoName} Lote: {coordinacion?.lote}
              </h4>
              <p className="text-center">El warehouse de esta coordinacion ya ha sido creado, para ver dirigirse
                al siguiente enlace</p>
              <div className="text-center">
                <Button onClick={() => navigate(`/viewWereHouse/${warehouse.werehouseId}`)}>Ver Warehouse</Button>
              </div>
            </div>
          </Row>
        </ShowWhen>
        <ShowWhen show={!warehouse}>
          <WarehouseForm coordinacion={coordinacion}
                         hasVehicle={true}
                         onSubmit={insertarWR}></WarehouseForm>
        </ShowWhen>
      </Spin>
    </WarehouseProvider>
  );
};

export default WerehouseCoor;
