import {ApiService} from "./ApiService";
import {server} from "../environments/environment";

export const getExtraItems = async () => {
  const response = await ApiService.get(`${server}extraItem`);
  const res = await response.json();

  if (res && res.status) {
    return res.list;
  }

  throw new Error(res.message);
}
