import {addExtraItem} from "../../Servicios/WerehouseService";
import ExtraItemReactiveForm from "./ExtraItemReactiveForm";
import React, {useState} from "react";
import Spinner from "../../shared/spinner";
import {Button} from "antd";

const ExtraItemForm = ({ warehouseId, onSubmit }) => {
  const [loading, setLoading] = useState(false);
  const [clearFields, setClearFields] = useState(false);
  const [showRequired, setShowRequired] = useState(false);
  const [reloadExtraItems, setReloadExtraItems] = useState(false);

  const [extraItem, setExtraItem] = useState(undefined);

  const handleFinish = async () => {
    setShowRequired(false);
    if (!extraItem || !extraItem.extraItemName || !extraItem.weight || !extraItem.quantity) {
      setShowRequired(true);
      return;
    }

    const input = {
      ...extraItem,
      weight: +extraItem.weight,
      quantity: +extraItem.quantity
    };

    if (warehouseId) {
      setLoading(true);
      await addExtraItem(warehouseId, input);
      setLoading(false);
    }

    setClearFields(true);
    setReloadExtraItems(true);
    if (onSubmit) onSubmit(input);

    setTimeout(() => {
      setClearFields(false);
      setReloadExtraItems(false);
    }, 2000);
  }

  return(
    <Spinner loading={loading}>
      <ExtraItemReactiveForm clearFields={clearFields}
                             showRequired={showRequired}
                             reloadExtraItems={reloadExtraItems}
                             getExtraItem={extraItem => setExtraItem(extraItem)}/>
      <div className="text-end">
        <Button type={'primary'} onClick={() => handleFinish()}>Guardar</Button>
      </div>
    </Spinner>
  );
}

export default ExtraItemForm;
